import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { navigate } from "gatsby";
import { parseUrl, parseTitle, parseImage } from "helpers/string";
import { FloatingPlayIcon } from "components";

const colorCodes = ["#FFA500", "#FF0000", "#008000"];

const LandingVerticalVideoScroller = ({
  content,
  isSeriesDetailPage,
  mediaId,
  setIsLandingPage,
  setIsLoading,
}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});

  const changePage = ({ link }) => {
    setIsLoading(true);
    setIsLandingPage(false);
    navigate(link, {
      state: {
        // mediaRetVal: mediaData,
        retVal: content,
        fromLandingPage: true,
      },
    });
  };

  const renderRightItem = ({ item, image, color, link, index }) => {
    return (
      <Grid
        key={`ItemRight${index}`}
        container
        className={classes.gridContainer}
      >
        <Grid item md={6} lg={6} className={classes.imageGrid}>
          <Box
            className={classes.imageBox}
            onClick={() => {
              changePage({ link });
            }}
          >
            <FloatingPlayIcon float />
            <img
              className={classes.image}
              alt={item.title}
              src={image}
              onError={(e) => {
                if (!errors[item.title]) {
                  const ev = { ...errors };
                  ev[item.title] = true;
                  setErrors(ev);
                  e.target.src = item.image;
                }
                e.target.src = item.image;
              }}
            />
          </Box>
        </Grid>
        <Grid item md={5} lg={5}>
          <Box className={classes.descriptionBox}>
            <Typography variant="h1" style={{ color, fontSize: "2.528rem",  fontFamily: "HelveticaBold"  }}>
              {(item.landing_heading && item.landing_heading.toUpperCase()) ||
                (item.title && item.title.toUpperCase())}
            </Typography>
            {item.landing_part && (
              <Typography variant="h2" style={{ color, fontSize: "1.328rem",  fontFamily: "HelveticaBold" }}>
                {item.landing_part && item.landing_part.toUpperCase()}
              </Typography>
            )}
            <br />
            <Typography variant="body2" className={classes.desktopDescription}>
              {item.description}
            </Typography>
            <Button
              variant="outlined"
              className={classes.watchButton}
              onClick={() => {
                changePage({ link });
              }}
            >
              <Typography className={classes.buttonText}>Watch Now</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderLeftItem = ({ item, image, color, link, index }) => {
    return (
      <Grid
        key={`ItemLeft${index}`}
        container
        className={classes.gridContainer}
      >
        <Grid item md={5} lg={5}>
          <Box className={classes.descriptionBox}>
            <Typography variant="h1" style={{ color, fontSize: "2.528rem",  fontFamily: "HelveticaBold"  }}>
              {(item.landing_heading && item.landing_heading.toUpperCase()) ||
                (item.title && item.title.toUpperCase())}
            </Typography>
            {item.landing_part && (
              <Typography variant="h2" style={{ color, fontSize: "1.328rem",  fontFamily: "HelveticaBold"  }}>
                {item.landing_part && item.landing_part.toUpperCase()}
              </Typography>
            )}
            <br />
            <Typography variant="body2" className={classes.desktopDescription}>
              {item.landing_desc || item.description}
            </Typography>
            <Button
              variant="outlined"
              className={classes.watchButton}
              onClick={() => {
                changePage({ link });
              }}
            >
              <Typography className={classes.buttonText}>Watch Now</Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item md={1} lg={1}></Grid>
        <Grid item md={6} lg={6} className={classes.imageGrid}>
          <Box
            className={classes.imageBox}
            onClick={() => {
              setIsLandingPage(false);
              navigate(link, {
                state: {
                  // mediaRetVal: mediaData,
                  retVal: content,
                  fromLandingPage: true,
                },
              });
            }}
          >
            <FloatingPlayIcon float />
            <img
              className={classes.image}
              alt={item.title}
              src={image}
              onError={(e) => {
                if (!errors[item.title]) {
                  const ev = { ...errors };
                  ev[item.title] = true;
                  setErrors(ev);
                  e.target.src = item.image;
                }
                e.target.src = item.image;
              }}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderMobileItem = ({ item, image, link, index }) => {
    let fImage = parseImage(item.smallFeaturedImage);
    fImage = (fImage && parseUrl(fImage)) || image;
    return (
      <Box key={`ItemMobile${index}`} className={classes.mobileBox}>
        <Box
          className={classes.imageBox}
          onClick={() => {
            changePage({ link });
          }}
        >
          <FloatingPlayIcon float />
          <img
            className={classes.mobileImage}
            alt={item.title}
            src={fImage}
            onError={(e) => {
              if (!errors[item.title]) {
                const ev = { ...errors };
                ev[item.title] = true;
                setErrors(ev);
                e.target.src = item.image;
              }
              e.target.src = item.image;
            }}
          />
        </Box>
        <Box className={classes.mobileDescriptionBox}>
          {/* <Typography variant="h1" style={{ color }}>
            {item.title && item.title.toUpperCase()}
          </Typography> */}
          <br />
          <Typography variant="body2" className={classes.desktopDescription}>
            {item.landing_desc || item.description}
          </Typography>
          <Button
            variant="outlined"
            className={classes.watchButton}
            onClick={() => {
              changePage({ link });
            }}
          >
            <Typography className={classes.buttonText}>Watch Now</Typography>
          </Button>
        </Box>
      </Box>
    );
  };

  const renderItems = () => {
    if (!(content && content.playlist && content.playlist.length)) {
      return null;
    }
    return content.playlist.map((item, index) => {
      let image = parseImage(item.landing_image || item.smallFeaturedImage);
      if (!image) {
        image = item.images.find((i) => i.width === 480);
        image = image && image.src;
      }
      image = parseUrl(image);
      const title = parseTitle(item.title);
      let link = `/details/${title}/${item.mediaid}/`;
      if (item.seriesPlayListId) {
        link = `/series/${title}/${item.mediaid}/`;
      } else if (isSeriesDetailPage) {
        const seriesTitle = parseTitle(content.title);
        const title = parseTitle(item.title);
        link = `/series/${seriesTitle}/season-${item.seasonNumber}/ep-${item.episodeNumber}/${title}/${mediaId}/`;
      }

      const colorIndex = index % colorCodes.length;
      const color = colorCodes[colorIndex];

      return (
        <React.Fragment key={`item${index}`}>
          {renderMobileItem({ item, image, color, link, index })}
          {(index % 2 === 0 &&
            renderRightItem({ item, image, color, link, index })) ||
            renderLeftItem({ item, image, color, link, index })}
        </React.Fragment>
      );
    });
  };

  return (
    <Box>
      <Box className={classes.box}>{renderItems()}</Box>
    </Box>
  );
};

export default LandingVerticalVideoScroller;

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontWeight: 700,
    fontSize: "0.9rem",
  },
  imageGrid: {
    display: "flex",
    flexDirection: "column",
  },
  mobileBox: {
    margin: "2vw 0vw",
    [theme.breakpoints.up(960)]: {
      display: "none",
    },
  },
  gridContainer: {
    marginBottom: "80px",
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
  },
  descriptionBox: {
    margin: "16px 32px 16px 60px",
  },
  mobileDescriptionBox: {
    textAlign: "center",
  },
  watchButton: {
    margin: "12px 0",
    color: "white",
    borderRadius: "15px",
    borderWidth: "1px",
    borderColor: "white",
    height: "30px",
    width: "140px",
    [theme.breakpoints.down(960)]: {},
  },

  title: {
    cursor: "pointer",
    padding: "8px",
    marginLeft: "8px",
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0",
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".85rem",
    },
  },
  box: {
    marginTop: "50px",
    [theme.breakpoints.up("md")]: {
      // margin: "0 16px !important",
    },
  },
  mobileImage: {
    borderRadius: "8px",
    width: "100%",
  },
  imageBox: {
    width: "100%",
    position: "relative",
    cursor: "pointer",
    // outlineWidth: "inherit",
    // cursor: "pointer",
    // marginRight: "16px !important",
    // transition: "transform 0.3s",
    // "&:hover": {
    //   transform: "scale(1.03)",
    //   transitionTimingFunction: "ease-in-out",
    //   transition: "0.3s",
    // },
  },
  root: {
    flexGrow: 1,
    // width: `240px !important`,
    height: "125px !important",
    margin: 8,
  },
  image: {
    width: "100%",
    // width: `240px !important`,
    // marginTop: "5px",
    // borderRadius: "8px",
    // height: "180px !important",
    [theme.breakpoints.between(0, 768)]: {
      // height: "90px !important",
    },
    [theme.breakpoints.between(769, 1223)]: {
      // height: "115px !important",
    },
  },
}));
