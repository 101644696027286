import React, { useEffect, useState } from "react";
import {
  Box,
  NoSsr,
  Typography,
  Button,
  Modal,
  useScrollTrigger,
  Fade,
  Grid,
} from "@material-ui/core";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { makeStyles } from "@material-ui/core/styles";
import serviceHelper from "helpers/serviceHelper";
import { Helmet } from 'react-helmet';

import {
  Layout,
  TopNavigation,
  FullScreenTextLoader,
  FloatingPlayIcon,
} from "components";
import { LandingVideoDetail } from "components/VideoDetail";
import { VideoDetailNavigation } from "components/TopNavigation";
import { VideoPlayer, VideoScroller, CenterText } from "components/index";
import { navigate } from "gatsby-link";
import { parseUrl, parseTitle, parseImage } from "helpers/string";
import env from "config";
const { relatedFilmsId, siteUrl } = env;

export default function VideoDetail({
  location,
  mediaId,
  pageContext,
  data: contextData,
}) {
  contextData =
    contextData && contextData.sitePage && contextData.sitePage.context;
  mediaId = mediaId || pageContext.key || "";
  let [data, setData] = useState();
  let [image, setImage] = useState();
  let [relatedItems, setRelatedItems] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const [isLandingPage, setIsLandingPage] = useState(false);
  useEffect(() => {
    getData();
  }, [mediaId]);

  useEffect(() => {
    let timer;
    if (!isImageLoaded && image) {
      const loadImg = new Image();
      loadImg.src = image;
      loadImg.onload = () => setIsImageLoaded(true);

      timer = setTimeout(() => {
        if (!isImageLoaded) {
          setIsImageLoaded(true);
        }
      }, 11000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [image]);

  const fromSearchPage = location.state && location.state.fromSearchPage;
  const fromFilmsPage = location.state && location.state.fromFilmsPage;
  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });
  const getData = async () => {
    const fromLandingPage = location.state && location.state.fromLandingPage;

    let data = location.state && location.state.item;
    let relatedItems = location.state && location.state.relatedItems;
    const retVal = await serviceHelper.getVideo(mediaId);
    setIsLoading(false);
    setOpen(false);
    if (retVal.isError) {
      // navigate('/');
      return null;
    }
    data =
      retVal.data &&
      retVal.data.playlist &&
      retVal.data.playlist.length &&
      retVal.data.playlist[0];
    image =
      data &&
      data.images &&
      data.images.length &&
      data.images.find((i) => i.width === 720);
    let image = image && image.src;

    if (!image && fromSearchPage) {
      image = data.custom && data.custom.featuredImage;
    }
    setImage(image);
    setData(data);
    setIsLandingPage(data && data.landing === "true" && !fromLandingPage);

    if (fromFilmsPage || !relatedItems) {
      const relatedData = await serviceHelper.getPlaylist(relatedFilmsId);
      if (!relatedData.isError && relatedData.data) {
        relatedItems = relatedData.data || [];
      }
    }
    setRelatedItems(relatedItems);
  };

  function handleVideo() {
    setOpen(true);
  }

  function formatDuration(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }
  const classes = useStyles({ image: parseUrl(image) });


  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + "H": "";
    var mDisplay = m > 0 ? m + "M" : "";
    var sDisplay = s > 0 ? s + "S" : "";
    return "PT"+hDisplay + mDisplay + sDisplay;     
  }

  const timestamp = (contextData && contextData.pubdate) || (data && data.pubdate);
  let pubDate = null;
  if(timestamp != undefined){
    pubDate = new Date(timestamp).toLocaleString();
  }
  else{
    // default time stamp in case of undefined time stamp
    pubDate = new Date(1640249797).toLocaleString();
  }
  

  let duration = (contextData && contextData.duration) || (data && data.duration) || 0;
  if(duration > 0){
    duration = secondsToHms(duration);
  }

  const seo = {
    title: (contextData && contextData.title) || (data && data.title) || "",
    description:
      (contextData && contextData.description) ||
      (data && data.description) || "",
    tags: (contextData && contextData.tags) || (data && data.tags) || "",
    image: parseImage((contextData && contextData.smallFeaturedImage) || image),
    videoInfo: true,
    duration: duration,
    contentUrl: (contextData && contextData.mrss_video_url) || (data && data.mrss_video_url),
    embedUrl: (contextData && contextData.mrss_video_url) || (data && data.mrss_video_url),
    uploadDate: pubDate,
  };

  const renderVideo = () => {
    if (isLoading) {
      return <section style={{ height: "60vh" }}></section>;
    }
    if (!data) {
      // navigate('/');
      return (
        <React.Fragment>
          <TopNavigation />
          <CenterText />
        </React.Fragment>
      );
    }

    if (isLandingPage) {
      return (
        <LandingVideoDetail
          setIsLandingPage={setIsLandingPage}
          isVideoDetailPage={true}
          mediaId={mediaId}
          data={data}
          relatedItems={relatedItems}
          image={image}
          isLoading={isLoading}
        />
      );
    }

    const videoUrl =
      (fromSearchPage && data.custom && data.custom.mrss_video_url) ||
      data.mrss_video_url;

    const title =
      relatedItems && relatedItems.title && relatedItems.title.toLowerCase();
    const categoryLinkTitle = parseTitle(title);
    return (
      <React.Fragment>
        <NoSsr defer>
          <Modal
            className={classes.modal}
            disableBackdropClick={true}
            open={open}
            onClose={() => {
              trackCustomEvent({
                category: "video",
                action: data.title,
                label: "Exit",
              });
              setOpen(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.paper}>
              <VideoPlayer
                title={data.title}
                mediaId={mediaId}
                sources={data.sources}
                open={open}
                url={videoUrl}
                cuepoints={data.cuepoints}
              />
            </div>
          </Modal>
          {(open && (
            <VideoDetailNavigation
              onClose={() => setOpen(false)}
              title={data.title}
            />
          )) ||
            null}
        </NoSsr>
        <NoSsr>
          {/* <FloatingPlayIcon float onClick={handleVideo} /> */}
          <Box style={{ position: "relative", cursor: "pointer" }}>
            <FloatingPlayIcon
              float
              onClick={handleVideo}
              height={75}
              width={75}
            />
            <div className={classes.section}>
              {(!open && (
                <Fade in={scrollTrigger} timeout={4000}>
                  <TopNavigation
                    backgroundColor={
                      (!scrollTrigger && "transparent") || "black"
                    }
                  />
                </Fade>
              )) ||
                null}

              <Box className={classes.box}>
                <Typography variant="h1">
                  {data.title && data.title.toUpperCase()}
                </Typography>
                {/* <br /> */}

                {/* <br /> */}
                {/* <Button
                variant="outlined"
                style={{
                  color: "white",
                  borderRadius: "15px",
                  borderWidth: "1px",
                  borderColor: "white",
                }}
                onClick={handleVideo}
              >
                Watch Now
              </Button> */}
                {/* <br /> */}
                <br />
                <Typography
                  variant="body2"
                  className={classes.desktopDescription}
                >
                  {data.description}
                </Typography>
                <br />
                <Grid container>
                  <Grid item>
                    <Typography variant="body1">
                      {formatDuration(data.duration)}
                    </Typography>
                  </Grid>
                  <Grid item  className={classes.desktopIndexId}>
                    {(title !== "you may also like" && (
                      <>
                        <Typography
                          variant="body1"
                          className={classes.desktopFeedId}
                          onClick={() => {
                            navigate(
                              `/category/${categoryLinkTitle}/${relatedItems.feedid}/`
                            );
                          }}
                        >
                          {title}
                        </Typography>
                      </>
                    )) ||
                      null}
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Box>
          <Box className={classes.mobileBox}>
            <Box className={classes.titleCategory}>
              <Typography variant="h1">
                {data.title && data.title.toUpperCase()}
              </Typography>
            </Box>
            {/* <br /> */}

            {/* <br /> */}
            {/* <Button
              variant="outlined"
              style={{
                color: "white",
                borderRadius: "15px",
                borderWidth: "1px",
                borderColor: "white",
              }}
              onClick={handleVideo}
            >
              Watch Now
            </Button>
            <br /> */}
            {/* <br /> */}
            <Typography variant="body1" className={classes.mobileDesciption}>
              {data.description}
            </Typography>
            <Grid
              container
              display="flex"
              className={classes.mobileDurationGrid}
            >
              <Grid item>
                <Typography variant="body1" className={classes.mobileDuration}>
                  {formatDuration(data.duration)}
                </Typography>
              </Grid>
              <Grid item>
                {(title !== "you may also like" && (
                  <Typography
                    variant="body1"
                    className={classes.link}
                    onClick={() => {
                      navigate(
                        `/category/${categoryLinkTitle}/${relatedItems.feedid}/`
                      );
                    }}
                  >
                    {title}
                  </Typography>
                )) ||
                  null}
              </Grid>
            </Grid>
          </Box>
          <section className={classes.videoScroller}>
            <VideoScroller
              fromSearchPage={fromSearchPage}
              content={relatedItems}
            ></VideoScroller>
          </section>
        </NoSsr>
      </React.Fragment>
    );
  };

  return (
      <Layout location={location} seo={seo}>
        <Helmet>
          <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org",
                    "@type": "VideoObject",
                    "name": "${seo.title}",
                    "description": "${seo.description}",
                    "thumbnailUrl": [
                      "${seo.image}"
                    ],
                    "uploadDate":  "${seo.uploadDate}",
                    "duration":  "${seo.duration}",
                    "contentUrl":  "${seo.contentUrl}"
                  }
                `}
          </script>
        </Helmet> 
        <FullScreenTextLoader open={isLoading && !isImageLoaded} />
        {renderVideo()}
      </Layout>
  );
}

const useStyles = makeStyles((theme) => ({
  mobileDurationGrid: {
    justifyContent: "space-between",
  },
  mobileDuration: {
    margin: "7px 0",
  },
  desktopDescription: {
    fontSize: "1rem",
    lineHeight: "1.4",
  },
  desktopFeedId: {
    cursor: "pointer",
    marginLeft: "30px", 
  },desktopIndexId:{zIndex:99},
  link: {
    cursor: "pointer",
    margin: "7px 0",
  
  },
  mobileDesciption: {
    margin: "8px 0",
    width: "100%",
  },
  videoScroller: {
    margin: "16px",
  },
  titleCategory: {
    // display: 'flex',
    // justifyContent: 'space-between'
  },
  modal: {
    backgroundColor: "black",
  },
  paper: {
    position: "absolute",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: "80vw",
    [theme.breakpoints.between(0, 600)]: {
      top: `50%`,
      left: `50%`,
      width: "100vw",
    },
  },
  text: {
    padding: 8,
  },
  mobileWatchBox: {
    display: "flex",
    justifyContent: "center",
  },
  section: {
    backgroundImage: ({ image }) =>
      `linear-gradient(90deg,#000 2%,transparent), url(${image})`,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: ({ image }) =>
        `linear-gradient(180deg, rgba(0,0,0,0.9) -2%, transparent 100%), url(${image})`,
    },
    [theme.breakpoints.down("md")]: {
      height: "32vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "77vh",
    },
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
  },
  box: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginLeft: "10vw",
    width: "28vw",
    marginTop: "15vh",
  },
  mobileBox: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    padding: "16px",
  },
}));
