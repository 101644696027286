import React from 'react'
import ReactJwPlayer from '../../helpers/jwplayer-react';
import env from '../../config';
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
const { jwPlayerLicenseKey, jwPlayerScriptUrl, adscheduleid, fallabckAdUrl } = env;

const VideoPlayer = (props) => {
    //const { playerId, open, sources, url, mediaId, title, cuepoints, setData } = props;
    let { playerId, open, sources, url, mediaId, title, cuepoints, setData } = props;
    const hls_source = sources?.find((s) => s?.file?.split('.')?.pop() === 'm3u8');
    sources = hls_source ? [hls_source] : sources;
    let config = null;
    if (typeof window !== 'undefined' && window.localStorage) {
        if(localStorage.getItem("config")){
            config = JSON.parse(localStorage.getItem("config"));
        }
    }

    let adUrl = config ? config.webAdUrl : fallabckAdUrl;
    let cueArray = typeof cuepoints === 'string' && cuepoints.split(",").filter(a => !!a) || [];
    let scheduleArray = [];
    let preRollAd = {           
        "tag" : [ adUrl ],
        "type" : "linear",
        "offset" : "pre"
    };
    scheduleArray.push(preRollAd);
    cueArray.map((item) => {
        let newItem = {           
            "tag" : [ adUrl ],
            "type" : "linear",
            "offset" : JSON.parse(item)
        };
        scheduleArray.push(newItem);
    });
    const ads = {
            "adscheduleid": adscheduleid,
            "client": "vast",
            "cuetext": "Advertisement",
            "outstream": false,
            "preloadAds": true,
            "vpaidcontrols": false,
            "rules": {
                "startOnSeek": "pre",
                "timeBetweenAds": 0
            },
            "schedule": scheduleArray
    };

    let s10 = false, p25 = false, p50 = false, p75 = false, p100 = false;

    if (open) {
        return (
            <ReactJwPlayer
                playerId={ playerId }
                advertising={ ads }
                ga={{ label: mediaId }}
                onTime={(e) => {
                    const { currentTime, duration } = e;
                    const p = currentTime * 100 / duration;

                    if (!p100 && p >= 100) {
                        p100 = true;

                        window.gtag("event", "100% Progress", {
                            category: 'video',
                            action: title,
                            label: '100% Progress',
                        })
                        return;
                    }

                    if (!p75 && p >= 75) {
                        p75 = true;

                        window.gtag("event", "75% Progress", {
                            category: 'video',
                            action: title,
                            label: '75% Progress',
                        })
                        return;
                    }

                    if (!p50 && p >= 50) {
                        p50 = true;

                        window.gtag("event", "50% Progress", {
                            category: 'video',
                            action: title,
                            label: '50% Progress',
                        })
                        return;
                    }

                    if (!p25 && p >= 25) {
                        p25 = true;

                        window.gtag("event", "25% Progress", {
                            category: 'video',
                            action: title,
                            label: '25% Progress',
                        })
                        return;
                    }

                    if (!s10 && currentTime >= 10) {
                        s10 = true;

                        window.gtag("event", "time", {
                            category: 'video',
                            action: title,
                            label: 'time',
                            currentTime: e && e.currentTime,
                            duration: e && e.duration
                        })
                        return
                    }
                }}
                onClose={(e) => {
                    window.gtag("event", "Exit", {
                        category: 'video',
                        action: title,
                        label: 'Exit',
                        value: e && e.currentTime
                    })
                }}
                onPlaylistItem={(e) => {
                    if (!setData) {
                        return;
                    }

                    setData(e.item);
                }}
                onReady={() => {
                    window.gtag("event", "ready", { 
                        label: 'ready',
                        category: 'video',
                        value:'uHcCZgceEeuXi1JhriPi1w',
                        mediaId,
                        title, 
                    })
                    // trackCustomEvent({
                    //     category: 'video',
                    //     action: title,
                    //     value:'uHcCZgceEeuXi1JhriPi1w',
                    //     label: 'ready',
                    //     mediaId,
                    //     title
                    // })
                }}
                onError={(e) => {
                    window.gtag("event", "Error", {
                        category: 'video',
                        action: title,
                        label: 'Error',
                        error: e
                    })
                }}
                onAdPlay={() => {
                    window.gtag("event", "Ad Play", {
                        category: 'video',
                        action: title,
                        label: 'Ad Play',
                    })
                }}
                onAdPause={() => {
                    window.gtag("event", "Ad Pause", { 
                        category: 'video',
                        label: "Ad Pause",
                        action: title,
                    })
                    // trackCustomEvent({
                    //     category: 'video',
                    //     action: title,
                    //     label: 'Ad Pause',
                    // })
                }}
                onBuffer={() => {
                    window.gtag("event", "Buffering", { 
                        label: 'Buffering',
                        category: 'video',
                        action: title,
                    })
                    // trackCustomEvent({
                    //     category: 'video',
                    //     action: title,
                    //     label: 'Buffering',
                    // })
                }}
                onBufferChange={() => {
                    window.gtag("event", "Rebuffer", { 
                        label: 'Rebuffer',
                        category: 'video',
                        action: title,
                    })
                    // trackCustomEvent({
                    //     category: 'video',
                    //     action: title,
                    //     label: 'Rebuffer',
                    // })
                }}
                licenseKey={jwPlayerLicenseKey}
                library={jwPlayerScriptUrl}
                file={url}
                playlist={sources}
                autostart={true}
                playlistIndex={(sources || []).findIndex((source) => source.title === title)}
            />
        );
    }
    return null
};

export default VideoPlayer;