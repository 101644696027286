import React from "react";
import styled from "styled-components";

const Icon = ({ height, width, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height || "63"}
      width={width || "63"}
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
      {...rest}
      // style="enable-background:new 0 0 100 125;"
      // xml:space="preserve"
    >
      <g>
        <StyledPath d="M50,5C25.1,5,5,25.1,5,50s20.1,45,45,45s45-20.1,45-45S74.9,5,50,5z M50,92C26.8,92,8,73.2,8,50S26.8,8,50,8   s42,18.8,42,42S73.2,92,50,92z" />
        <StyledPath d="M67.8,47.5l-23.7-17C43.6,30.2,43,30,42.4,30c-1.7,0-3,1.4-3,3v34c0,1.7,1.4,3,3,3c0.6,0,1.2-0.2,1.8-0.6   l23.7-17c0.8-0.6,1.3-1.5,1.3-2.5S68.6,48.1,67.8,47.5z M42.4,67C42.4,67,42.4,67,42.4,67l0,1.5L42.4,67l0-34.1L66,50L42.4,67z" />
      </g>
    </svg>
    // <svg
    //   height="50"
    //   width="63"
    //   viewBox="0 0 63 50"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...rest}
    // >
    //   <path
    //     opacity="0.86"
    //     d="M54.2 49.5H8.3C3.7 49.5 0 45.8 0 41.2V8.3C0 3.7 3.7 0 8.3 0H54.1C58.7 0 62.4 3.7 62.4 8.3V41.1C62.5 45.7 58.8 49.5 54.2 49.5Z"
    //     fill="#1B1C20"
    //   />
    //   <path d="M44.2 24.7002L20.3 36.8002V12.7002L44.2 24.7002Z" fill="white" />
    // </svg>
  );
};

const PlayIcon = ({ float, minHeight = "auto", onClick, height, width }) => {
  return (
    <StyledContainer onClick={onClick} float={float} minHeight={minHeight}>
      <StyledIcon color="#333" height={height} width={width} />
    </StyledContainer>
  );
};

export default PlayIcon;

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: ${({ minHeight }) => minHeight};
  opacity: 0.6;
  position: ${({ float }) => (float ? "absolute" : "relative")};
  text-align: center;
  width: 100%;
`;

const StyledPath = styled.path`
  fill: #ffffff;
`;

// ${({ theme }) => theme.breakpoints.down('sm')} {
//   display: none
// }

//  &: hover > svg {
//   box - shadow: 0 0 20px rgba(255, 255, 255, 8);
//   transition: all 0.1s ease -in -out;
//   transform: scale(1.15);
// }

const StyledIcon = styled(Icon)``;

// border-radius: 50%;
// box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);

// ${({ theme }) => theme.breakpoints.up("sm")} {
//   height: auto;
//   box-shadow: 0 0 20px rgba(255, 255, 255, 0.95);
// }
