import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Grid, Link as MLink } from '@material-ui/core';
import { IcoMoon } from "components";

import env from '../../config';


const AppIcons = () => {
  const classes = useStyles();
  const iconGrid = [{ href: env.appleTvUrl, alt: 'appleTv', logo: 'apple-tv', height: 55, width: 55, xs: 4, md: 4, lg: 2, xl: 2 },
    // { href: env.androidUrl, alt: 'Android', logo: 'android-seeklogocom', height: 55, width: 85, xs: 4, md: 4, lg: 3, xl: 3 },
    { href: env.rokuUrl, alt: 'Roku', logo: 'roku-seeklogocom', height: 55, width: 68, xs: 4, md: 4, lg: 3, xl: 3 },
    { href: env.iosUrl, alt: 'Ios', logo: 'ios', height: 55, width: 51, xs: 4, md: 4, lg: 2, xl: 2 },
    // { href: env.fireTvUrl, alt: 'FireTv', logo: 'fireTV', height: 68, width: 55, xs: 4, md: 4, lg: 2, xl: 2 }
  ];

  const mobileIconGrid = [
    { href: env.appleTvUrl, alt: 'appleTv', logo: 'apple-tv', height: 49, width: 49, xs: 3, md: 3, lg: 2, xl: 2 },
    { href: env.rokuUrl, alt: 'Roku', logo: 'roku-seeklogocom', height: 49, width: 56, xs: 4, md: 4, lg: 2, xl: 2 },
    { href: env.iosUrl, alt: 'Ios', logo: 'ios', height: 49, width: 42, xs: 3, md: 3, lg: 2, xl: 2 },
  ];
  const mobileGrid = [
    // { href: env.rokuUrl, alt: 'Roku', logo: 'roku-seeklogocom', height: 49, width: 56, xs: 4, md: 4, lg: 2, xl: 2 },
    // { href: env.androidUrl, alt: 'Android', logo: 'android-seeklogocom', height: 49, width: 63, xs: 4, md: 4, lg: 2, xl: 2 },
    // { href: env.fireTvUrl, alt: 'FireTv', logo: 'fireTV', height: 56, width: 49, xs: 4, md: 4, lg: 2, xl: 2 }
  ];

  const renderIcons = (type) => {
    let grid = '';
    switch (type) {
      case 'iconGrid':
        grid = iconGrid;
        break;
      case 'mobileIconGrid':
        grid = mobileIconGrid
        break;
      default:
        grid = mobileGrid
        break;
    }
    return (
      <Grid container className={classes[type]}>
        {grid.map((item, index) => {
          const style = { width: item.width, height: item.height };
          const { alt, href } = item;
          return (<Grid key={`${type}${index}`} item >
            <Box textAlign="center">
              <MLink href={href} aria-label={alt}
                rel="noopener noreferrer"
                target="_blank">
                <IcoMoon icon={item.logo} style={{ ...style }} />
              </MLink>
            </Box>
          </Grid>)
        })}
      </Grid>
    )
  }

  return (
    <Grid container justify="center">
      {renderIcons('iconGrid')}
      {renderIcons('mobileIconGrid')}
      {renderIcons('mobileGrid')}
    </Grid>
  );
};

export default AppIcons;

const useStyles = makeStyles((themes) => ({
  background: {
    padding: '8px !important',
    backgroundColor: '#585858',
    borderRadius: '3px'
  },
  iconGrid: {
    [themes.breakpoints.down('md')]: {
      display: 'none',
    },
    padding: '15px 25vw',
    justifyContent: 'space-evenly'
  },
  mobileIconGrid: {
    [themes.breakpoints.up('lg')]: {
      display: 'none',
    },
    [themes.breakpoints.up(700)]: {
      padding: '10px 25vw',
    },
    padding: '10px 8vw',
    justifyContent: 'space-around'
  },
  mobileGrid: {
    [themes.breakpoints.up('lg')]: {
      display: 'none',
    },
    [themes.breakpoints.up(700)]: {
      padding: '10px 15vw',
    },
    padding: '0 5vw',
    justifyContent: 'space-around'
  },
  mobileIcon: {
    padding: '0 10px',
    height: '100%',
    width: '65%'
  },
  iconRoot: {
    height: 65,
    width: 220
  },
  mobileImageIcon: {
    height: '100%',
    width: '55%'
  },
  imageIcon: {
    height: '100%',
    width: '50%'
  },
  streamText: {
    color: themes.body.color,
    padding: 16
  },
  divider: {
    backgroundColor: themes.body.color,
    width: '80%',
    margin: 16
  }
}));