import React, { useState, useEffect } from 'react';

import {
  Backdrop,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import CustomForm from './form';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import DocuWhite from 'images/DOCU-white.svg';

const url =
  "https://docplus.us6.list-manage.com/subscribe/post?u=f78824c246edc3e427072c350";
const NewsLetter = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const showModal = localStorage.getItem('showModal');
      if (!showModal) {
        setTimeout(() => {
          setOpen(true)
        }, 40000)
      }
    }
  }, [])

  const handleClose = () => {
    localStorage.setItem('showModal', 'no')
    setOpen(false)
  }
  return (
    <Backdrop
      transitionDuration={500}
      className={classes.backdrop}
      open={open}>
      <Box className={classes.rootBox}  >
        <Card className={classes.root} elevation={0}>
          <Box className={classes.closeIconBox}>
            <IconButton onClick={handleClose} aria-label="settings">
              <CloseIcon
                className={classes.closeIcon}></CloseIcon>
            </IconButton>
          </Box>
          <Box className={classes.formBox}
            m={3}
            justifyContent="center" >
            <Box display="flex"
              className={classes.headingBox}
              alignContent="center"
              justifyContent="center">
              <Box>
                <img className={classes.image}
                  alt='images/DOCU-white.svg'
                  src={DocuWhite} />
              </Box>
            </Box>
            <Box className={classes.form}>
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => {
                  return (
                    <CustomForm
                      classes={classes}
                      status={status}
                      message={message}
                      onValidated={formData => subscribe(formData)}
                      handleClose={handleClose}
                    />
                  )
                }}
              />
            </Box>
          </Box>
          <CardMedia
            className={classes.cover}
            image="https://res.cloudinary.com/dhg4fsecb/image/upload/c_scale,h_460,w_470/v1622219121/free_documentary_qjnx02.png"
            title="Documentary Newsletter"
          />
        </Card>
      </Box>
    </Backdrop >
  );
};

export default NewsLetter;

const useStyles = makeStyles((theme) => ({
  errorClass: {
    color: 'red',
    marginTop: '-10px',
    marginBottom: '20px'
  },
  image: {
    height: '15px'
  },
  form: {
    [theme.breakpoints.up(1400)]: {
      margin: '8vh 5vw',
    },
  },
  headingBox: {
    marginTop: '16px',
    marginBottom: '16px',
    [theme.breakpoints.up(1400)]: {
      marginTop: '5vh',
      marginBottom: '5vh',
    },
  },
  updateBox: {
    marginTop: '32px',
    marginBottom: '32px',
    [theme.breakpoints.up(1400)]: {
      marginTop: '5vh',
      marginBottom: '5vh',
    },
  },
  thankYouBox: {
    marginTop: '132px',
    marginBottom: '132px',
    [theme.breakpoints.up(1400)]: {
      marginTop: '25vh',
      marginBottom: '25vh',
    },
  },
  textRoot: {
    border: "white !important",
    borderRadius: "0",
    textAlign: 'center'
  },
  rootBox: {
    background: theme.body.backgroundColor,
    width: '80%'
  },
  closeIconBox: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      right: '14vw',
    },
  },
  formBox: {
    width: '50%',
    [theme.breakpoints.down(800)]: {
      width: '100%',
    },
  },
  input: {
    color: 'white',
    textAlign: 'center',
  },
  nameTextField: {
    color: 'white',
    width: '50%',
    paddingRight: 4,
    paddingBottom: 4,
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black"
    }
  },
  emailTextField: {
    color: 'white',
    width: '50%',
    paddingBottom: 4,
    paddingLeft: 4,
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple"
    }
  },
  cssLabel: {
    color: 'white !important',
    fontSize: '14px',
    [theme.breakpoints.down(800)]: {
      fontSize: '9px',
    },
  },
  notchedOutline: {
    '$.Mui-focused': {
      border: "1px solid white !important",
      borderRadius: "0"
    },
    border: "1px solid white !important",
    borderRadius: "0"
  },
  closeIcon: {
    color: theme.body.color,
    fontSize: 25
  },
  backdrop: {
    zIndex: 1500,
    color: "#fff",
  },
  card: {
    backgroundColor: 'white',
  },
  mobileContainer: {
    margin: '50px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  gridRoot: {
    width: '70%',
  },
  media: {
    height: 230,
  },
  root: {
    display: 'flex',
    borderRadius: 0,
    margin: 24,
    border: '2px solid white',
    background: theme.body.backgroundColor,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: '50%',
    [theme.breakpoints.down(800)]: {
      display: 'none',
    },
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

// const DesktopSection = styled.section`
//   background: ${({ theme }) => theme.body.backgroundColor};
//   justify-content: center;
//   display: flex; 
// `;
