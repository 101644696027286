import React from 'react';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';

import theme from '../../gatsby-theme-material-ui-top-layout/theme';
import { Footer, Seo, NewsLetter } from '../index';
import './Layout.css'

const Layout = ({ children, location, seo, isFooterIcons = true }) => {
  return (
    <ThemeProvider theme={theme} style={{ overflow: 'hidden' }}>
      <Section>
        <NewsLetter />
        <Seo location={location} seo={seo} />
        {children}
        <Footer location={location} isFooterIcons={isFooterIcons} />
      </Section>
    </ThemeProvider>
  );
};

export default Layout;

const Section = styled.section`
    background: ${({ theme }) => theme.body.backgroundColor};
    color: ${({ theme }) => theme.body.color};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;
