import React, { useEffect, useState } from 'react';
import {
    NoSsr,
    Grid,
} from '@material-ui/core';
import serviceHelper from 'helpers/serviceHelper';

import { Layout, TopNavigation, VideoList, CenterText } from 'components';

export default function Category({ location, feedId, pageContext }) {
    let [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let data = location.state && location.state.item;
        if (!(data)) {
            if (!feedId) {
                feedId = pageContext && pageContext.id;
            }
            const playlistData = await serviceHelper.getPlaylist(feedId);
            if (!playlistData.isError) {
                data = playlistData.data;
            }
        }
        setData(data);
        setIsLoading(false);
    }


    const seo = {
        title: pageContext && pageContext.title || data && data.title,
        description: pageContext && pageContext.description || data && data.description
    };

    const renderList = () => {
        if (isLoading) {
            return <section style={{ height: '60vh' }}>
            </section>;
        }

        if (!data) {
            return <CenterText />;
        }
        return (
            <NoSsr>
                <section id="header" style={{ margin: '15px' }} >
                    <Grid container spacing={1}>
                        <VideoList content={data} showCenterTitle={true} />
                    </Grid>
                </section>
                <section style={{ height: '5vh' }} />
            </NoSsr>
        )
    }

    return (
        <Layout location={location} seo={seo}>
            <TopNavigation />
            {renderList()}
        </Layout>
    );
}
