import axios from 'axios';
import env from '../config';

const { baseUrl, configUrl, searchUrl } = env;

const services = {
    getConfig: async () => {
        try {
            const config = await axios.get(configUrl);
            if (config && config.status === 200) {
                return {
                    isError: false,
                    data: config && config.data,
                }
            }
            return {
                isError: true,
                data: config && config.data,
            }
        } catch (err) {
            return {
                isError: true,
                error: err.message,
                data: err
            }
        }
    },
    getPlaylist: async (playlistId) => {
        try {
            const config = await axios.get(`${baseUrl}/v2/playlists/${playlistId}?sources=hls&page_limit=500`);
            return {
                isError: false,
                data: config && config.data,
            }
        } catch (err) {
            return {
                isError: true,
                error: err.message,
                data: err
            }
        }
    },

    getVideo: async (mediaId) => {
        try {
            const config = await axios.get(`${baseUrl}/v2/media/${mediaId}`);
            return {
                isError: false,
                data: config && config.data,
            }
        } catch (err) {
            return {
                isError: true,
                error: err.message,
                data: err
            }
        }
    },

    searchVideo: async (searchedKey) => {
        try {
            const data = await axios.post(`${searchUrl}`, {
                search: searchedKey
            },
                {
                    'Content-Type': 'application/x-www-form-urlencoded',
                })
            return {
                isError: false,
                data: data.data,
            }
        } catch (err) {
            return {
                isError: true,
                error: err.message,
                data: err
            }
        }
    },

};

export default services;
