import React from 'react';
import { Link, graphql, StaticQuery } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import { Hidden, Typography } from '@material-ui/core';

const SiteLinks = ({ initialLoad }) => {
    const classes = useStyles();
    if (!initialLoad) {
        return null;
    }

    return (
        <StaticQuery
            query={graphql`
                    query AllPagesRoutes {
                        allSitePage {
                            edges {
                                node {
                                    path
                                    context {
                                        title
                                    }
                                }
                            }
                        }
                    }
                    `}
            render={data => {
                return (
                    <section style={{ color: 'black !important' }}>
                        <Typography variant="h2" style={{ color: 'black' }} >
                            Home to the world’s best documentaries -- from Academy
                            Award winning classics and box office hits to festival darlings and cult favorites.
                            Nonfiction storytelling at its best, curated by the best.
                        </Typography>
                        {data.allSitePage.edges.map((item, index) => {
                            if (item.node.path.includes('[')) {
                                return null
                            }
                            return (
                                <React.Fragment key={`path${index}`}>
                                    <Link className={classes.linkColor}
                                        to={item.node.path}>{item.node && item.node.context
                                            && item.node.context.title || item.node.path}</Link>
                                    <br />
                                </React.Fragment>
                            )
                        })}
                    </section>
                )
            }
            }
        />
    )
};

export default SiteLinks;

const useStyles = makeStyles(() => ({
    linkColor: {
        color: 'black !important'
    }
}));
