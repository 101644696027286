export function truncate(str, m, useWordBoundary) {
    if (typeof str === 'string' || str instanceof String) {
        const n = m - 3;

        if (str.length <= n) {
            return str;
        }
        const subString = str.substr(0, n - 1); // the original check
        return (
            (useWordBoundary
                ? subString.substr(0, subString.lastIndexOf(' '))
                : subString) + '...'
        );
    } else {
        return '';
    }
}

export function parseUrl(url) {
    if (!url) return '';
    const splitter = url.split('https');
    if (splitter.length > 2) {
        const newUrl = `https${splitter[splitter.length - 1]}`;
        return newUrl;
    }
    return url;
}

export function parseTitle(title) {
    if (!title) return '';
    let filteredString = title && title.replace(/[^a-zA-Z1-9 ]/g, "");
    filteredString = filteredString.replace(/[ ]/g, "-").toLowerCase();
    return filteredString;
}

export function parseImage(imageUrl) {
    if (!imageUrl) return '';
    if (!(typeof window)) return '';
    let resizedUrl = imageUrl && imageUrl.replace('h_720', 'h_405');
    resizedUrl = resizedUrl && resizedUrl.replace('w_1280', 'w_720');
    return resizedUrl;
}