import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import './index.css';
import {
    AppBar,
    Container,
    IconButton,
    Toolbar,
    Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';


const VideoDetailNavigation = ({ backgroundColor, title, onClose }) => {
    const [height] = useState(0);
    const props = {
        backgroundColor
    }
    const classes = useStyles(props);

    return (
        <>
            <StyledAppBar
                style={{ top: height, transition: 'flex 0.3s ease-out' }}
                className={classes.appBar}
            >
                <Container maxWidth="lg">
                    <StyledToolbar>
                        <Typography onClick={() => navigate('/')} variant="h5" className={classes.title}>
                            {title}
                        </Typography>
                        <MobileCloseMenuSwitcher
                            {...{
                                edge: 'end',
                                'aria-label': 'menu',
                                'aria-haspopup': 'true',
                                onClick: onClose,
                            }}
                        >
                            <CloseIcon className={classes.menuIcon} />
                        </MobileCloseMenuSwitcher>
                    </StyledToolbar>
                </Container>
            </StyledAppBar>
            <Toolbar />
        </>
    );
};

export default VideoDetailNavigation;

const useStyles = makeStyles((theme) => ({

    title: {
        cursor: 'pointer',
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: ({ backgroundColor }) => `${backgroundColor || theme.body.backgroundColor} !important`,
        boxShadow: 'none'
    },
    menuIcon: {
        color: theme.typography.color
    }
}));



const MobileCloseMenuSwitcher = styled(IconButton)`
  & svg {
    font-size: 3rem !important;
  }
`;

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  color: ${({ mode, theme }) =>
        mode === 'light' ? theme.palette.text.primary : '#ffffff'};
  background-color: ${({ mode }) =>
        mode === 'light' ? '#ffffff' : 'rgba(0, 0, 0, 0.98)'};

  max-height: 70px;
  margin-top: ${({ height }) => height};
  z-index: 1301;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
`;
