import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Grid, Link, Typography } from '@material-ui/core';

import plexLogo from 'images/plexlogo.png';
import samsungLogo from 'images/samsung-tv-plus-logo.png';
import vizioLogo from 'images/vizio.png';
import xumoLogo from 'images/xumologo.png';
import tcl from 'images/download.svg';
import localNow from 'images/localnow.svg';
import rakuten from 'images/rakuten.png';
import distro from 'images/distro.png';
import stremium from 'images/stremium-home1-01.png';
import ustnow from 'images/ustvnow-360-logo-retina-white.png';
import vidaa from 'images/vidaa-logo.png';
import freevee from 'images/Freevee_logo.png';
import slingFreeStream from 'images/Sling-Freestream.png';

const AppIcons = ({ compact }) => {
  const classes = useStyles();
  const iconGrid = compact ?
    [
      [
        { logo: samsungLogo, label: "Channel 1466", alt: "samsung", href: "https://www.samsung.com/us/televisions-home-theater/tvs/tvplus/all-channels/", height: "", width: "120" },
        { logo: xumoLogo, label: "Channel 262", alt: "xumo", href: "https://www.xumo.tv/on-now/99991638/documentary", height: "", width: "120" },
        { logo: vizioLogo, label: "Channel 573", alt: "vizio", href: "https://www.vizio.com/en/watchfreeplus", height: "", width: "120" },
        { logo: localNow, label: "Channel 475", alt: "local now", href: "https://www.plex.tv/", height: "", width: "140" }
      ],
      [
        { logo: tcl, label: "Channel 3960", alt: "plex", href: "https://www.plex.tv/", height: "", width: "120" },
        { logo: rakuten, label: "", alt: "plex", href: "https://www.plex.tv/", height: "", width: "120" },
        { logo: plexLogo, label: "", alt: "plex", href: "https://www.plex.tv/", height: "", width: "120" },
        { logo: distro, label: "", alt: "distro", href: "https://www.plex.tv/", height: "", width: "120" }
      ],
      [
        { logo: stremium, label: "", alt: "stremium", href: "https://www.stremium.com/", height: "", width: "180" },
        { logo: ustnow, label: "", alt: "ustnow", href: "https://www.ustvnow.com/", height: "", width: "120" },
        { logo: vidaa, label: "", alt: "vidaa", href: "https://www.vidaa.com/", height: "", width: "120" },
        { logo: freevee, label: "", alt: "Freevee", href: "https://www.freevee.com/", height: "", width: "140" },
        { logo: slingFreeStream, label: "", alt: "Sling FreeStream", href: "https://www.sling.com/freestream", height: "", width: "140" },
      ]
    ]
    :
    [
      [
        { logo: samsungLogo, label: "Channel 1466", alt: "samsung", href: "https://www.samsung.com/us/televisions-home-theater/tvs/tvplus/all-channels/", height: "", width: "120" },
        { logo: xumoLogo, label: "Channel 262", alt: "xumo", href: "https://www.xumo.tv/on-now/99991638/documentary", height: "", width: "120" },
        { logo: vizioLogo, label: "Channel 573", alt: "vizio", href: "https://www.vizio.com/en/watchfreeplus", height: "", width: "120" },
        { logo: localNow, label: "Channel 475", alt: "local now", href: "https://www.plex.tv/", height: "", width: "140" }
      ],
      [
        { logo: tcl, label: "Channel 3960", alt: "plex", href: "https://www.plex.tv/", height: "", width: "120" },
        { logo: rakuten, label: "", alt: "plex", href: "https://www.plex.tv/", height: "", width: "120" },
        { logo: plexLogo, label: "", alt: "plex", href: "https://www.plex.tv/", height: "", width: "120" },
        { logo: distro, label: "", alt: "distro", href: "https://www.plex.tv/", height: "", width: "120" }  
      ],
      [
        { logo: stremium, label: "", alt: "stremium", href: "https://www.stremium.com/", height: "", width: "180" },
        { logo: ustnow, label: "", alt: "ustnow", href: "https://www.ustvnow.com/", height: "", width: "120" },
        { logo: vidaa, label: "", alt: "vidaa", href: "https://www.vidaa.com/", height: "", width: "120" },
        { logo: freevee, label: "", alt: "Freevee", href: "https://www.freevee.com/", height: "", width: "140" },
        { logo: slingFreeStream, label: "", alt: "Sling FreeStream", href: "https://www.sling.com/freestream", height: "", width: "140" },
      ]
    ];

  const mobileGrid = compact
    ?
    [
      [
        { logo: samsungLogo, label: "Channel 1466", alt: "samsung", href: "https://www.samsung.com/us/televisions-home-theater/tvs/tvplus/all-channels/", height: "", width: "70" },
        { logo: xumoLogo, label: "Channel 262", alt: "xumo", href: "https://www.xumo.tv/on-now/99991638/documentary", height: "", width: "70" },
        { logo: vizioLogo, label: "Channel 573", alt: "vizio", href: "https://www.vizio.com/en/watchfreeplus", height: "", width: "70" }
      ],
      [
        { logo: localNow, label: "Channel 475", alt: "localnow", href: "https://www.plex.tv/", height: "", width: "70" },
        { logo: tcl, label: "Channel 3960", alt: "TCL", href: "https://www.plex.tv/", height: "", width: "70" },
        { logo: rakuten, label: "", alt: "plex", href: "https://www.plex.tv/", height: "", width: "70" }
      ],
      [
        { logo: plexLogo, label: "", alt: "plex", href: "https://www.plex.tv/", height: "", width: "70" },
        { logo: distro, label: "", alt: "distro", href: "https://www.plex.tv/", height: "", width: "70" }
      ],
      [
        { logo: stremium, label: "", alt: "stremium", href: "https://www.stremium.com/", height: "", width: "100" },
        { logo: ustnow, label: "", alt: "ustnow", href: "https://www.ustvnow.com/", height: "", width: "70" },
        { logo: vidaa, label: "", alt: "vidaa", href: "https://www.vidaa.com/", height: "", width: "70" },
      ],
      [
        { logo: freevee, label: "", alt: "Freevee", href: "https://www.freevee.com/", height: "", width: "180" },
        { logo: slingFreeStream, label: "", alt: "Sling FreeStream", href: "https://www.sling.com/freestream", height: "", width: "180" },
      ]
    ]
    :
    [
      [
        { logo: samsungLogo, label: "Channel 1466", alt: "samsung", href: "https://www.samsung.com/us/televisions-home-theater/tvs/tvplus/all-channels/", height: "", width: "72" },
        { logo: xumoLogo, label: "Channel 262", alt: "xumo", href: "https://www.xumo.tv/on-now/99991638/documentary", height: "", width: "72" }
      ],
      [
        { logo: vizioLogo, label: "Channel 573", alt: "vizio", href: "https://www.vizio.com/en/watchfreeplus", height: "", width: "72" },
        { logo: localNow, label: "Channel 475", alt: "localnow", href: "https://www.plex.tv/", height: "", width: "95" }
      ],
      [
        { logo: tcl, label: "Channel 3960", alt: "TCL", href: "https://www.plex.tv/", height: "", width: "120" },
        { logo: rakuten, label: "", alt: "plex", href: "https://www.plex.tv/", height: "", width: "85" }
      ],
      [
        { logo: plexLogo, label: "", alt: "plex", href: "https://www.plex.tv/", height: "", width: "72" },
        { logo: distro, label: "", alt: "distro", href: "https://www.plex.tv/", height: "", width: "85" }
      ],
      [
        { logo: freevee, label: "", alt: "Freevee", href: "https://www.freevee.com/", height: "", width: "180" },
        { logo: slingFreeStream, label: "", alt: "Sling FreeStream", href: "https://www.sling.com/freestream", height: "", width: "180" },
      ]
    ];

  const renderIcons = (type) => {
    let grid = '';
    switch (type) {
      case 'iconGrid':
        grid = iconGrid;
        break;
      default:
        grid = mobileGrid
        break;
    }
    return grid.map((childGrid, index) => {
      let boxClass = classes.imageBox;
      return (
        <Grid container key={`parent${index}`}
         direction="row"
                      justifyContent="center"
                      alignItems="center" className={classes[type]} >
                      {childGrid.map((item, inerIndex) => {
                          const { alt, href } = item;
                          if(grid.length > 2 && index < 2){
                            boxClass = compact ? classes.compactTopImageBox : classes.topImagesBox
                          }
                          return (
                          <Grid key={`${inerIndex}`} container={true} item xs={6} md={3} sm={4} lg={3} className={compact ? classes.compactLogoItem : classes.logoItem} >
                              <Box textAlign="center" className={boxClass}  alignContent="center" >
                                  <Link href={href} aria-label={alt}
                                      rel="noopener noreferrer"
                                      target="_blank">
                                      <img alt={alt} height={item.height} width={item.width} src={item.logo} />
                                     
                                  </Link>
                                 
                              </Box>
                              {
                                compact ? <></> : <Box id="channelText" className={classes.bottomLabel} textAlign="center">
                                  <Typography variant='subtitle1'>{item.label}</Typography>
                                </Box>
                              }              
                          </Grid>)
                      })}
                  </Grid>
      )
    })
    
  }

  return (
    <Grid container justifyContent="center">
      {renderIcons('iconGrid')}
      {renderIcons('mobileGrid')}
    </Grid>
  );
};

export default AppIcons;

const useStyles = makeStyles((themes) => ({
  bottomLabel: {
    bottom: '0px',
    position: 'absolute',
    left: '50%',
    transform: `translate(-50%, 0)`
  },
  imageBox: {
    paddingBottom: '30px',
    [themes.breakpoints.down('sm')]: {
      paddingBottom: '50px',
    },
  },
  topImagesBox: {
    paddingBottom: '30px',

    [themes.breakpoints.down('sm')]: {
      paddingBottom: '50px',
    },
  },
  compactTopImageBox: {
    paddingBottom: '10px',
  },
  logoItem: {
    position: 'relative',
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingTop: '40px',
  },
  compactLogoItem: {
    position: 'relative',
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingTop: '10px',
  },
  background: {
    padding: '8px !important',
    backgroundColor: '#585858',
    borderRadius: '3px'
  },
  iconGrid: {
    [themes.breakpoints.down('md')]: {
      display: 'none',
    },
    display: 'table',
    padding: '20px 15vw',
    justifyContent: 'space-between',
    tableLayout: 'fixed'
  },
  mobileIconGrid: {
    [themes.breakpoints.up('lg')]: {
      display: 'none',
    },
    [themes.breakpoints.up(700)]: {
      padding: '10px 15vw',
    },
    padding: '10px 8vw',
    justifyContent: 'space-around'
  },
  mobileGrid: {
    [themes.breakpoints.up('lg')]: {
      display: 'none',
    },
    [themes.breakpoints.up(700)]: {
      padding: '10px 15vw',
    },
    display: 'table',
    tableLayout: 'fixed',
    padding: '2vh 5vw',
    justifyContent: 'space-around'
  },
  mobileIcon: {
    padding: '0 10px',
    height: '100%',
    width: '65%'
  },
  iconRoot: {
    height: 65,
    width: 220
  },
  mobileImageIcon: {
    height: '100%',
    width: '55%'
  },
  imageIcon: {
    height: '100%',
    width: '50%'
  },
  streamText: {
    color: themes.body.color,
    padding: 16
  },
  divider: {
    backgroundColor: themes.body.color,
    width: '80%',
    margin: 16
  }
}));