import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, navigate } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import './index.css';
import {
    AppBar,
    Box,
    Container,
    Drawer,
    IconButton,
    MenuItem,
    Toolbar,
    Link as MLink,
} from '@material-ui/core';
import env from '../../config';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import DocuWhite from 'images/DOCU-white.svg';

const headersData = [
    {
        label: 'home',
        href: '/',
    },
    {
        label: 'films',
        href: '/films/',
    },
    {
        label: 'search',
        href: '/search/',
    },
    {
        label: 'info',
        href: '/info/',
        isHiddenDesktop: true
    },
    {
        label: 'about',
        href: '/about/',
    },
];

const socialLinks = [
    {
        label: 'Facebook',
        href: env.fbUrl,
        icon: FacebookIcon,
    },
    {
        label: 'Twitter',
        href: env.twitterUrl,
        icon: TwitterIcon,
    },
    {
        label: 'Instagram',
        href: env.igUrl,
        icon: InstagramIcon,
    },
    {
        label: 'YouTube',
        href: env.ytUrl,
        icon: YouTubeIcon,
    },
];

const LinkSocial = ({ href, label, icon }) => {
    return (
        <SocialLink
            aria-label={label}
            to={href}
            rel="noopener noreferrer"
            target="_blank"
        >
            {icon.type.render()}
        </SocialLink>
    );
};

const TopNavigation = ({ backgroundColor, location }) => {
    const [mobileView, setMobileView] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [height] = useState(0);
    const props = {
        backgroundColor
    }
    const classes = useStyles(props);

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setMobileView(true)
                : setMobileView(false);
        };

        setResponsiveness();

        window.addEventListener('resize', () => setResponsiveness());

        return window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    const displayDesktop = () => {
        return (
            <StyledToolbar>
                <Box>
                    <img onClick={() => navigate('/')} className={classes.image}
                        alt='images/DOCU-white.svg'
                        src={DocuWhite} />
                </Box>
                <MenuList>
                    <div style={{ marginRight: 16 }}>{getDesktopMenuButtons()}</div>
                </MenuList>
            </StyledToolbar>
        );
    };

    const displayMobile = () => {
        const handleDrawerOpen = () => setDrawerOpen(true);
        const handleDrawerClose = () => setDrawerOpen(false);

        return (
            <StyledToolbar>
                <Box>
                    <img onClick={() => navigate('/')} className={classes.image}
                        alt='images/DOCU-white.svg'
                        src={DocuWhite} />
                </Box>
                {!drawerOpen ? <MobileMenuSwitcher
                    {...{
                        edge: 'end',
                        'aria-label': 'menu',
                        'aria-haspopup': 'true',
                        onClick: handleDrawerOpen,
                    }}
                >
                    <MenuIcon className={classes.menuIcon} />
                </MobileMenuSwitcher> : <MobileCloseMenuSwitcher
                    {...{
                        edge: 'end',
                        'aria-label': 'menu',
                        'aria-haspopup': 'true',
                        onClick: handleDrawerClose,
                    }}
                >
                    <CloseIcon className={classes.menuIcon} />
                </MobileCloseMenuSwitcher>}
                <Drawer
                    {...{
                        navWidth: '100%',
                        anchor: 'right',
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                        variant: 'temporary',
                    }}
                >
                    <DrawerContainer>
                        <Box className={classes.mobileLinks} >
                            {headersData.map(({ label, href }) => {
                                return (
                                    <MenuItem key={label}>
                                        <MobileStyledLink
                                            className={classes.mobileText}
                                            onClick={() => handleDrawerClose()} to={href}>{label}</MobileStyledLink>
                                    </MenuItem>
                                );
                            })}
                        </Box>
                        <Footer >
                            <StyledContainerLinks maxWidth={false}>
                                <Box display="flex" flexDirection="row">
                                    {socialLinks.map(({ label, href, icon }) => (
                                        <LinkSocial key={label} label={label} href={href} icon={icon} />
                                    ))}
                                </Box>
                            </StyledContainerLinks>
                        </Footer>
                    </DrawerContainer>
                </Drawer>
            </StyledToolbar>
        );
    };

    const getDesktopMenuButtons = () => {
        return headersData.map(({ label, href, isHiddenDesktop }) => {
            if (isHiddenDesktop) return null;
            return (
                <StyledLink
                    className={(location && location.pathname === href) && classes.underline}
                    key={label} to={href}>{label}</StyledLink>
            );
        });
    };

    return (
        <React.Fragment>
            <StyledAppBar
                style={{
                    top: height,
                    transition: "flex 0.3s ease-out",
                    backgroundColor: backgroundColor,
                }}
                className={classes.appBar}
            >
                <Container maxWidth={false}>
                    {mobileView ? displayMobile() : displayDesktop()}
                </Container>
            </StyledAppBar>
            <Toolbar />
        </React.Fragment>
    );
};

export default TopNavigation;

const useStyles = makeStyles((theme) => ({
    image: {
        height: '15px',
        cursor: "pointer",
    },
    underline: {
        content: '',
        left: 0,
        bottom: '1px',
        borderWidth: '0 0 2px',
        borderStyle: 'solid',
    },
    mobileLinks: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70vh',
    },
    root: {
        flexGrow: 1,
    },
    title: {
        cursor: 'pointer',
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: 'black',
        boxShadow: 'none'
    },
    mobileText: {
        color: `${theme.body.color} !important`,
    },
    menuIcon: {
        color: theme.typography.color
    }
}));


const StyledContainerLinks = styled(Container)`
    bottom: 0;
    position: fixed;
  font-size: 10px;
  padding-bottom: 10vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
    align-items: center;

  & .links {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    line-height: 20px;
    padding-top: 10px;

    & a {
      padding: 0 8px;
      white-space: nowrap;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    & .links {
      flex-direction: row;
      font-size: 10px;
      padding-top: 0;
    }
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: center;
    & .links {
      font-size: 14px;
      padding: 0;
    }
  }
`;

const SocialLink = styled(MLink)`
  & > svg {
    color: white;
    margin: 0 10px;
  }
`;

const DrawerContainer = styled.div`
  background-color: ${({ theme }) => theme.body.backgroundColor};
  color:  ${({ theme }) => theme.typography.color};
  height: 100vh;
  padding-top: 50px;
  li {
    padding: 15px;
    ${({ theme }) => theme.breakpoints.between(0, 800)} {
        padding: 5px;
    }
  }
`;

const MenuList = styled.div`
  display: inline-flex;
`;

const MobileMenuSwitcher = styled(IconButton)`
  & svg {
    font-size: 2rem !important;
  }
`;

const MobileCloseMenuSwitcher = styled(IconButton)`
  & svg {
    font-size: 2rem !important;
  }
`;

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  background-color: ${({ backgroundColor, theme }) =>
        backgroundColor || theme.body.backgroundColor};

  max-height: 70px;
  margin-top: ${({ height }) => height};
  z-index: 1301;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
`;


const StyledLink = styled(Link)`
    text-decoration: none;
    display: inline-block;
    color: #ffffff;
    font-size: 18px;
    margin: 16px;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;


const MobileStyledLink = styled(Link)`
    text-decoration: none;
    color: black;
    font-size: 18px;
    
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const Footer = styled.footer`
  flex-grow: 1;
`;