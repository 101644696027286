import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Carousel } from '../index'
import {
    Box,
    Typography,
} from '@material-ui/core';
import { navigate } from "gatsby"
import { parseUrl, parseTitle, parseImage } from 'helpers/string'

const VideoScroller = ({ content, fromSearchPage, setImagesLoaded,
    isImagesLoaded, landingPage, scrolled }) => {
    const classes = useStyles();
    const [dragging, setDragging] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [items, setItems] = useState(2);

    useEffect(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth > 760 && windowWidth < 1280) {
            setItems(7)
        } else if (windowWidth > 1279) {
            setItems(15)
        }
    }, [])

    useEffect(() => {
        if (!isImagesLoaded && content && content.playlist && content.playlist.length) {
            const loadImage = image => {
                return new Promise((resolve, reject) => {
                    const loadImg = new Image()
                    loadImg.src = image
                    loadImg.onload = () => resolve(image)

                    loadImg.onerror = err => reject(err)
                })
            }

            Promise.all(content.playlist.map((item, index) => {
                if (landingPage && !scrolled) {
                    if (index < items) {
                        item.smallFeaturedImage && loadImage(parseImage(item.smallFeaturedImage))
                    }
                } else {
                    item.smallFeaturedImage && loadImage(parseImage(item.smallFeaturedImage))
                }
            }))
                .then(() => setImagesLoaded())
                .catch(err => console.log("Failed to load images", err))
        }

        let timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000)
        return (() => {
            clearTimeout(timer);
        })
    }, [])

    const callDragging = () => {
        if (!isLoading) {
            setDragging(true)
        }
    }

    const slickConfig = {
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: false,
        swipeToSlide: true,
        variableWidth: true,
        draggable: true,
        beforeChange: () => callDragging(),
        afterChange: () => setDragging(false),
        responsive: [
            {
                breakpoint: 1370,
                settings: {
                    arrows: true,
                },
            },
            {
                breakpoint: 780,
                settings: {
                    arrows: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    const renderItems = () => {
        if (fromSearchPage) {
            if (content && content.videos && content.videos.length) {
                return content.videos.map(item => {
                    let image = parseImage(item.custom && item.custom.smallFeaturedImage);
                    if (!image && !item.custom.seriesPlayListId) return null;
                    const title = parseTitle(item.title);
                    image = parseUrl(image);
                    let link = `/details/${title}/${item.key}`;
                    const seriesPlayListId = item.seriesPlayListId || item?.custom?.seriesPlayListId;
                    if (seriesPlayListId) {
                        link = `/series/${title}/${item.key}/`
                    }
                    return (
                        <div key={title} onClick={() => {
                            if (dragging) return;
                            navigate(link, {
                                state: {
                                    item, relatedItems: content,
                                    fromSearchPage
                                }
                            })
                        }}
                            className={classes.imageBox} >
                            <img className={classes.image}
                                alt={item.title}
                                src={image} />
                        </div>)
                })
            }
        }
        if (!(content && content.playlist && content.playlist.length)) {
            return null;
        }
        return content.playlist.map((item, index) => {
            if (landingPage && !scrolled) {
                if (index > items) {
                    return null
                }
            }
            let image = parseImage(item.smallFeaturedImage);
            if (!image) {
                image = item.images.find(i => i.width === 480);
                image = image && image.src;
            }
            image = parseUrl(image);
            const title = parseTitle(item.title);
            let link = `/details/${title}/${item.mediaid}/`;
            if (item.seriesPlayListId) {
                link = `/series/${title}/${item.mediaid}/`
            }
            return (
                <div key={title} onClick={() => {
                    if (dragging) return;
                    navigate(link, {
                        state: {
                            item, relatedItems: content
                        }
                    })
                }}
                    className={classes.imageBox} >
                    <img className={classes.image}
                        alt={item.title}
                        src={image}
                        onError={(e) => {
                            if (!errors[item.title]) {
                                const ev = { ...errors };
                                ev[item.title] = true;
                                setErrors(ev)
                                e.target.src = item.image
                            }
                            e.target.src = item.image
                        }} />
                </div>)
        })
    }
    let title = content && content.title && content.title.toLowerCase();
    if (fromSearchPage) {
        title = 'search'
    }
    if ((content && content.playlist && content.playlist.length && content.playlist.length < 6) ||
        (content && content.videos && content.videos.length && content.videos.length < 6)) {
        slickConfig.infinite = false;
        slickConfig.arrows = false;
    }
    const categoryLinkTitle = parseTitle(title);
    return (
        <Box>
            <Typography onClick={() => {
                navigate(`/category/${categoryLinkTitle}/${content.feedid}/`)
            }} className={classes.title}
                variant="h6">{title}</Typography>
            <Box className={classes.box}>
                <Carousel
                    config={slickConfig} >
                    {renderItems()}
                </Carousel>
            </Box>
        </Box>
    );
};

export default VideoScroller;

const useStyles = makeStyles((theme) => ({
    title: {
        cursor: 'pointer',
        padding: '8px',
        marginLeft: '8px',
        fontSize: '1.2rem',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 0',
            fontSize: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '.85rem',
        },
    },
    box: {
        margin: '0 8px !important',
        [theme.breakpoints.up('md')]: {
            margin: '0 16px !important'
        },
    },
    imageBox: {
        outlineWidth: 'inherit',
        cursor: 'pointer',
        marginRight: '16px !important',
        transition: 'transform 0.3s',
        '&:hover': {
            transform: 'scale(1.03)',
            transitionTimingFunction: 'ease-in-out',
            transition: '0.3s',
        },
    },
    root: {
        flexGrow: 1,
        // width: `240px !important`,
        height: '125px !important',
        margin: 8
    },
    image: {
        // width: `240px !important`,
        marginTop: '5px',
        borderRadius: '8px',
        height: '125px !important',
        [theme.breakpoints.between(0, 768)]: {
            height: '90px !important',
        },
        [theme.breakpoints.between(769, 1223)]: {
            height: '115px !important',
        }
    }
}));
