import React from 'react';

import Slider from 'react-slick';
import Icon from '@material-ui/core/Icon';
import styled from 'styled-components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      style={{ color: 'white' }}
      className={className}
      onClick={onClick}
    >
      <Icon >
        <ChevronRightIcon />
      </Icon>
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      style={{ color: 'white' }}
      className={className}
      onClick={onClick}
    >
      <Icon >
        <ChevronLeftIcon />
      </Icon>
    </div>
  );
}


const Carousel = ({
  arrows = true,
  autoplay = true,
  autoplaySpeed = 3000,
  centerMode = false,
  centerPadding,
  cssEase = 'ease',
  config,
  dots = true,
  draggable = false,
  loop = true,
  mobileFirst = true,
  pauseOnFocus = false,
  pauseOnHover = true,
  slidesToScroll,
  slidesToShow,
  speed = 500,
  children,
  variableWidth = false
}) => {
  let slickConfig = {
    arrows,
    autoplay,
    autoplaySpeed,
    centerMode,
    centerPadding,
    cssEase,
    dots,
    draggable,
    infinite: loop,
    mobileFirst,
    pauseOnFocus,
    pauseOnHover,
    slidesToScroll,
    slidesToShow,
    speed,
    variableWidth
  };

  if (config) {
    slickConfig = config;
  }

  slickConfig.nextArrow = <NextArrow />
  slickConfig.prevArrow = <PrevArrow />

  return <StyledSlider {...slickConfig}>{children}</StyledSlider>;
};

export default Carousel;

const StyledSlider = styled(Slider)`
`