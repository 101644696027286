import VideoScroller from './VideoScroller';
import SeriesVideoScroller from './SeriesVideoScroller';
import LandingVideoScroller from "./LandingVideoScroller";
import LandingVerticalVideoScroller from "./LandingVerticalVideoScroller";

export default VideoScroller;

export {
  SeriesVideoScroller,
  LandingVideoScroller,
  LandingVerticalVideoScroller,
};