import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Carousel } from "../index";
import { navigate } from "gatsby";
import { Box, Typography } from "@material-ui/core";
import { parseUrl, parseTitle, parseImage } from "helpers/string";

const SeriesVideoScroller = ({
  content,
  seasonNumber,
  mediaId,
  mediaData,
  setOpen,
  location,
  // , isSeriesPage, setVideoData
}) => {
  const classes = useStyles();
  const [dragging, setDragging] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState(seasonNumber);
  const [seasons, setSeasons] = useState({});
  useEffect(() => {
    const tempSeasons = {};
    if (content && content.playlist && content.playlist.length) {
      content.playlist.forEach((item) => {
        if (!tempSeasons[item.seasonNumber]) {
          tempSeasons[item.seasonNumber] = true;
        }
      });
      setSeasons(tempSeasons);
    }
  }, [content]);

  const slickConfig = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    swipeToSlide: true,
    variableWidth: true,
    draggable: true,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          arrows: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const renderItems = () => {
    if (!(content && content.playlist && content.playlist.length)) {
      return null;
    }
    return content.playlist.map((item) => {
      if (item.seasonNumber !== selectedSeason) return null;

      let image = parseImage(item.smallFeaturedImage);
      if (!image) {
        image = item.images.find((i) => i.width === 480);
        image = image && image.src;
      }
      image = parseUrl(image);
      const title = parseTitle(item.title);
      // const date = new Date(item.pubdate * 1000);
      // const formattedDate = date && `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      return (
        <div
          key={title}
          onClick={() => {
            if (dragging) return;
            // if (isSeriesPage) {
            //     setVideoData(item, mediaData)
            // } else {
            const seriesTitle = parseTitle(mediaData.title);
            const link = `/series/${seriesTitle}/season-${item.seasonNumber}/ep-${item.episodeNumber}/${title}/${mediaId}/`;

            if (location && location.pathname === link) {
              setOpen(true);
            } else {
              navigate(link, {
                state: {
                  mediaRetVal: mediaData,
                  retVal: content,
                  // playVideo: true,
                },
              });
            }
            // }
          }}
          className={classes.imageBox}
        >
          <img className={classes.image} alt={item.title} src={image} />
        </div>
      );
    });
  };
  // <Box className={classes.textBox}>
  //     <Typography variant="h6">
  //         EP{item.episodeNumber} - {item.title}
  //     </Typography>
  //     <Typography variant="body1" className={classes.dateTime}>
  //         {formatDuration(item.duration)} | {formattedDate}
  //     </Typography>
  //     <Typography variant="body2" className={classes.description}>
  //         {item.description}
  //     </Typography>
  // </Box>

  const renderSeasons = () => {
    const seasonNumbers = Object.keys(seasons);
    if (seasonNumbers.length) {
      return seasonNumbers.map((item) => {
        return (
          <Typography
            key={item}
            display="inline"
            onClick={() => {
              setSelectedSeason(item);
            }}
            className={
              (item === selectedSeason && classes.underlinedTitle) ||
              classes.title
            }
            variant="h6"
          >
            Season {item}
          </Typography>
        );
      });
    }
  };

  if (
    (content &&
      content.playlist &&
      content.playlist.length &&
      content.playlist.length < 6) ||
    (content &&
      content.videos &&
      content.videos.length &&
      content.videos.length < 6)
  ) {
    slickConfig.infinite = false;
    slickConfig.arrows = false;
  }

  return (
    <Box style={{ marginTop: "20px" }}>
      <Box className={classes.seasonBox}>{renderSeasons()}</Box>
      <Box className={classes.box}>
        <Carousel config={slickConfig}>{renderItems()}</Carousel>
      </Box>
    </Box>
  );
};

export default SeriesVideoScroller;

const useStyles = makeStyles((theme) => ({
  seasonBox: {
    margin: "24px 4px",
  },
  underlineText: {
    textDecoration: "underline",
  },
  description: {
    marginTop: "2px",
  },
  dateTime: {
    color: "grey",
  },
  textBox: {
    marginTop: "4px",
    width: "215px",
    [theme.breakpoints.between(0, 600)]: {
      width: "150px",
    },
  },
  title: {
    cursor: "pointer",
    padding: "4px",
    marginLeft: "16px",
    fontSize: "1.4rem",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0",
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".85rem",
    },
  },
  underlinedTitle: {
    left: 0,
    bottom: "1px",
    borderWidth: "0 0 2px",
    borderStyle: "solid",
    cursor: "pointer",
    padding: "8px",
    marginLeft: "16px",
    fontSize: "1.4rem",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0",
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".85rem",
    },
  },
  box: {
    margin: "0 8px !important",
    [theme.breakpoints.up("md")]: {
      margin: "0 16px !important",
    },
  },
  imageBox: {
    outlineWidth: "inherit",
    cursor: "pointer",
    marginRight: "16px !important",
    transition: "transform 0.3s",
    marginLeft: "8px",
    "&:hover": {
      transform: "scale(1.03)",
      transitionTimingFunction: "ease-in-out",
      transition: "0.3s",
    },
  },
  root: {
    flexGrow: 1,
    // width: `240px !important`,
    height: "125px !important",
    margin: 8,
  },
  image: {
    // width: `240px !important`,
    marginTop: "5px",
    borderRadius: "8px",
    height: "125px !important",
    [theme.breakpoints.between(0, 768)]: {
      height: "90px !important",
    },
    [theme.breakpoints.between(769, 1223)]: {
      height: "115px !important",
    },
  },
}));
