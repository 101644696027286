import React from 'react';
import { Helmet } from 'react-helmet';
// import { useStaticQuery, graphql } from "gatsby"

import env from 'config';
const { siteUrl } = env;

function Seo({ lang, location, seo }) {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `
  // );

  const site = {};

  if (!location) {
    return null;
  }

  const metaDescription =
    seo.description ||
    `Home to the world’s best documentaries -- from Academy 
    Award winning classics and box office hits to festival darlings and cult favorites. 
    Nonfiction storytelling at its best, curated by the best.`;

  const title = seo.title || "DOCUMENTARY+";

  const url = `${siteUrl}${location.pathname}`;
  const videoInfo = seo.videoInfo;

  // OpenGraph values
  const og = {
    type: seo.type || 'website',
    title: title,
    description: metaDescription,
    url: url,
  };

  if (seo.image) {
    if (seo.image.includes('http')) {
      og.image = {
        url: seo.image,
        alt: 'Documentary+',
      };
    } else {
      og.image = {
        url: `${siteUrl}${seo.image}`,
        alt: title,
      };
    }
  } else {
    og.image = {
      url: `${siteUrl}/images/socialcard.jpg`,
      alt: 'Documentary+',
    };
  }

  const tags = (typeof seo.tags === 'string') && seo.tags || `Documentaries, 
  movies, films, awarded, crime, featured, story telling`;

  const defaultTitle = site.siteMetadata?.title;
  const meta = seo.meta || [];
  lang = lang || 'en';
  const videoObject = {};
  
  return (
    <Helmet
      defer={false}
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        // ...videoMeta,
        {
          name: `google-site-verification`,
          content: "bI4oWrU6Yu9b0_eLOINVF7MikVREQQXkGf7IiKqNeE0",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: tags,
        },
        {
          name: `twitter:card`,
          content: "summary_large_image",
        },
        {
          name: `twitter:site`,
          content: "@DocPlusByXTR",
        },
        {
          name: `twitter:creator`,
          content: "@DocPlusByXTR",
        },
        {
          property: `og:site_name`,
          content: "DOCUMENTARY+",
        },
        {
          name: `twitter:title`,
          content: og.title,
        },
        {
          name: `twitter:description`,
          content: og.description,
        },
        {
          name: `twitter:image`,
          content: og.image.url,
        },
        {
          property: `og:title`,
          content: og.title,
        },

        {
          property: `og:description`,
          content: og.description,
        },
        {
          property: `og:type`,
          content: og.type,
        },
        {
          property: `og:locale`,
          content: "en_US",
        },
        {
          property: `og:image`,
          content: og.image.url,
        },
        {
          property: `og:url`,
          content: url,
        },
      ].concat(meta)}
    >
    </Helmet>
  );
}


export default Seo;
