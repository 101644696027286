import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DocPlus from "images/docPlus.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={500} direction="up" ref={ref} {...props} />;
});

// const texts = ['D', 'O', 'C', '+'];
const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    color: "white",
  },
  root: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: "translateZ(0)",
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    "@media all and (-ms-high-contrast: none)": {
      display: "none",
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "black",
  },
  paper: {
    backgroundColor: "black",
    color: "white",
  },
  font: {
    width: "75%",
  },
}));

export default function ServerModal({ open }) {
  const classes = useStyles();
  // const [index, setIndex] = useState(0);
  const [progress, setProgress] = React.useState(10);
  const [show, setShow] = useState(false);

  useEffect(() => {
    let timer;
    if (progress < 97) {
      timer = setTimeout(() => {
        setProgress(progress + 2);
      }, 200);
    }
    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  useEffect(() => {
    if (open) {
      setShow(true);
    } else {
      setProgress(100);
      setTimeout(() => {
        setShow(false);
      }, 400);
    }
  }, [open]);

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      PaperComponent={Box}
      style={{
        backgroundColor: "black",
        boxShadow: "none",
      }}
      PaperProps={{
        root: classes.paper,
      }}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <Box
        position="relative"
        display="inline-flex"
        // PaperProps={{
        //   root: classes.paper,
        // }}
      >
        <CircularProgress
          classes={{
            colorPrimary: classes.colorPrimary,
          }}
          size={120}
          variant="determinate"
          value={progress}
          thickness={1}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          // PaperProps={{
          //   root: classes.paper
          // }}
        >
          <img
            className={classes.font}
            alt="images/DocPlus.svg"
            src={DocPlus}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
