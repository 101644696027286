import React, { useEffect, useState } from "react";
import {
  Box,
  NoSsr,
  Typography,
  Button,
  Modal,
  useScrollTrigger,
  Grid,
  Fade
} from "@material-ui/core";
import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import serviceHelper from "helpers/serviceHelper";
import { Helmet } from 'react-helmet';

import {
  Layout,
  TopNavigation,
  FullScreenTextLoader,
  CenterText,
  LandingVideoDetail,
  FloatingPlayIcon
} from "components";
import { VideoDetailNavigation } from "components/TopNavigation";
import { VideoPlayer } from "components/index";
import { SeriesVideoScroller } from "components/VideoScroller";
import { parseUrl, parseTitle, parseImage } from "helpers/string";

export default function SeriesVideoDetail({
  location,
  mediaId,
  pageContext,
  episodeNumber,
  season,
  isSeriesPage,
}) {
  isSeriesPage = (pageContext && pageContext.isSeriesPage) || isSeriesPage;
  let [data, setData] = useState();
  let [mediaData, setMediaData] = useState();
  let [image, setImage] = useState();
  let [seriesItems, setSeriesItems] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const [isLandingPage, setIsLandingPage] = useState(false);

  useEffect(() => {
    getData();
  }, [episodeNumber, season]);

  useEffect(() => {
    let timer;
    if (!isImageLoaded && image) {
      const loadImg = new Image();
      loadImg.src = image;
      loadImg.onload = () => setIsImageLoaded(true);

      timer = setTimeout(() => {
        if (!isImageLoaded) {
          setIsImageLoaded(true);
        }
      }, 11000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [image]);

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });
  const getData = async () => {
    if (!mediaId) {
      mediaId = pageContext && pageContext.mediaId;
    }
    const fromLandingPage = location.state && location.state.fromLandingPage;
    let retVal = location.state && location.state.retVal;
    let mediaRetVal = location.state && location.state.mediaRetVal;
    const playVideo = location.state && location.state.playVideo;
    // let isPathUpdated = location.state && location.state.isPathUpdated;
    let mediaData;
    if (!mediaRetVal) {
      mediaRetVal = await serviceHelper.getVideo(mediaId);
      if (mediaRetVal.isError) {
        // navigate('/');
        setIsLoading(false);
        return null;
      }
      mediaData =
        mediaRetVal.data &&
        mediaRetVal.data.playlist &&
        mediaRetVal.data.playlist.length &&
        mediaRetVal.data.playlist[0];

      retVal = await serviceHelper.getPlaylist(
        mediaData && mediaData.seriesPlayListId
      );
      if (retVal.isError) {
        // navigate('/');
        setIsLoading(false);
        return null;
      }
      data = retVal.data && retVal.data.playlist;
      if (mediaData && mediaData.landing === "true" && !fromLandingPage) {
        // if (!(data && data.length)) {
        //   data = tempData;
        // } else {
        data = retVal.data;
        // }

        setIsLandingPage(!fromLandingPage);

        const sorted = data.playlist.sort((a, b) =>
          parseInt(a.episodeNumber) > parseInt(b.episodeNumber)
            ? 1
            : parseInt(b.episodeNumber) > parseInt(a.episodeNumber)
            ? -1
            : 0
        );
      }
      retVal = retVal.data;
    } else {
      data = retVal.playlist;
      mediaData = mediaRetVal;
    }

    if (data && data.length && retVal.playlist.length) {
      const sortedPlaylist = data.sort((a, b) =>
        parseInt(a.episodeNumber) > parseInt(b.episodeNumber)
          ? 1
          : parseInt(b.episodeNumber) > parseInt(a.episodeNumber)
          ? -1
          : 0
      );
      retVal.playlist = sortedPlaylist;
      if (season) {
        season = season.split("season-")[1];
      }
      if (episodeNumber) {
        episodeNumber = episodeNumber.split("ep-")[1];
      }
      season = (pageContext && pageContext.seasonNumber) || season || "1";
      const episode =
        (pageContext && pageContext.episodeNumber) || episodeNumber || "1";
      const temp = data.find(
        (item) => item.seasonNumber === season && item.episodeNumber === episode
      );
      data = temp || data[0];
    }
    // if (!isSeriesPage && !isPathUpdated && !(pageContext && pageContext.mediaId)) {
    //     const seriesTitle = parseTitle(retVal.title);
    //     const title = parseTitle(data.title);
    //     navigate(
    //         `/series/${seriesTitle}/season-${data.seasonNumber}/ep-${data.episodeNumber}/${title}/${mediaId}/`,
    //         {
    //             replace: true,
    //             state: {
    //                 isPathUpdated: true,
    //                 retVal,
    //                 mediaRetVal: mediaData
    //             }
    //         }
    //     )
    // } else {

    setSeriesItems(retVal);
    setVideoData(data, mediaData);
    setOpen(!!playVideo);
    // }
  };

  const setVideoData = (data, mediaData) => {
    let image = "";
    if (isSeriesPage) {
      image = mediaData && mediaData.image;
    }
    if (!image) {
      image =
        data &&
        data.images &&
        data.images.length &&
        data.images.find((i) => i.width === 720);
      image = image && image.src;
    }
    setData(data);
    setMediaData(mediaData);
    setImage(image);
    setOpen(false);
    setIsLoading(false);
  };

  const handleVideo = () => {
    if (isSeriesPage) {
      const media = (pageContext && pageContext.mediaId) || mediaId;
      const seriesTitle = parseTitle(mediaData.title);
      const title = parseTitle(data.title);
      const link = `/series/${seriesTitle}/season-${data.seasonNumber}/ep-${data.episodeNumber}/${title}/${media}/`;
      navigate(link, {
        state: {
          mediaRetVal: mediaData,
          retVal: seriesItems,
          playVideo: true,
        },
      });
    } else {
      setOpen(true);
    }
  };

  function formatDuration(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }
  const classes = useStyles({ image: parseUrl(image) });


  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + "H": "";
    var mDisplay = m > 0 ? m + "M" : "";
    var sDisplay = s > 0 ? s + "S" : "";
    return "PT"+hDisplay + mDisplay + sDisplay;     
  }

  const timestamp = (pageContext && pageContext.pubdate) || (data && data.pubdate);
  let pubDate = null;
  if(timestamp != undefined){
    pubDate = new Date(timestamp).toLocaleString();
  }
  else{
    // default time stamp in case of undefined time stamp
    pubDate = new Date(1640249797).toLocaleString();
  }

  let duration = (pageContext && pageContext.duration) || (data && data.duration) || 0;
  if(duration > 0){
    duration = secondsToHms(duration);
  }

  const seo = {
    title: (pageContext && pageContext.title) || (seriesItems && seriesItems.title) || (data && data.title) || "",
    description:(pageContext && pageContext.description) || (data && data.description) || "",
    tags: (pageContext && pageContext.tags) || (data && data.tags) || "",
    image: parseImage((pageContext && pageContext.smallFeaturedImage) || image),
    videoInfo: true,
    duration: duration,
    contentUrl: (data && data.link) || "",
    embedUrl: (data && data.link) || "",
    uploadDate: pubDate,
  };

  const renderVideo = () => {
    if (isLoading) {
      return <section style={{ height: "60vh" }}></section>;
    }
    if (isLandingPage) {
      return (
        <LandingVideoDetail
          setIsLoading={setIsLoading}
          setIsLandingPage={setIsLandingPage}
          isSeriesDetailPage={true}
          mediaId={(pageContext && pageContext.mediaId) || mediaId}
          data={mediaData}
          relatedItems={data}
          image={image}
          isLoading={isLoading}
        />
      );
    }

    if (
      !(
        data &&
        seriesItems &&
        seriesItems.playlist &&
        seriesItems.playlist.length
      )
    ) {
      return (
        <React.Fragment>
          <TopNavigation />
          <CenterText />
        </React.Fragment>
      );
    }
    const videoUrl = data.mrss_video_url;

    return (
      <React.Fragment>
        <NoSsr defer>
          <Modal
            className={classes.modal}
            disableBackdropClick={true}
            open={open}
            onClose={() => {
              trackCustomEvent({
                category: "video",
                action: data.title,
                label: "Exit",
              });
              setOpen(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.paper}>
            <VideoPlayer
                title={data.title}
                mediaId={(pageContext && pageContext.mediaId) || mediaId}
                sources={seriesItems.playlist || data.sources}
                open={open}
                url={videoUrl}
                cuepoints={data.cuepoints}
                setData={setData}
              />
            </div>
          </Modal>
          {(open && (
            <VideoDetailNavigation
              onClose={() => setOpen(false)}
              title={data.title}
            />
          )) ||
            null}
        </NoSsr>

        {/* start of episode wwith floating play button screen */}
        {
          (!isSeriesPage && data.seasonNumber && data.episodeNumber) ?
          (
            <NoSsr>
            {/* <FloatingPlayIcon float onClick={handleVideo} /> */}
            <Box style={{ position: "relative", cursor: "pointer" }}>
              <FloatingPlayIcon
                float
                onClick={handleVideo}
                height={75}
                width={75}
              />
              <div className={classes.section}>
                {(!open && (
                  <Fade in={scrollTrigger} timeout={4000}>
                    <TopNavigation
                      backgroundColor={
                        (!scrollTrigger && "transparent") || "black"
                      }
                    />
                  </Fade>
                )) ||
                  null}

                <Box className={classes.box}>
                  <Typography variant="h1">
                    {data.title && data.title.toUpperCase()}
                  </Typography>
                  <br />
                  <Typography
                    variant="body2"
                    className={classes.desktopDescription}
                  >
                    {data.description}
                  </Typography>
                  <br />
                  <Grid container>
                    <Grid item>
                      <Box container display="flex" justifyContent="flex-start" alignItems="center">
                        <Typography variant="body1" className={classes.durationDiv}>
                          {formatDuration(data.duration)}
                        </Typography>
                        <Typography variant="body1" className={classes.seasonDiv}>
                          {`Season ${data.seasonNumber}`}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Box>
            <Box className={classes.mobileBox}>
              <Box className={classes.titleCategory}>
                <Typography variant="h1">
                  {data.title && data.title.toUpperCase()}
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.mobileDesciption}>
                {data.description}
              </Typography>
              <Grid
                container
                display="flex"
                className={classes.mobileDurationGrid}
              >
                <Grid item>
                  <Box container display="flex" justifyContent="flex-start" alignItems="center">
                    <Typography variant="body1" className={classes.durationDiv}>
                      {formatDuration(data.duration)}
                    </Typography>
                    <Typography variant="body1" className={classes.seasonDiv}>
                      {`Season ${data.seasonNumber}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <section className={classes.videoScroller}>
              <SeriesVideoScroller
                content={seriesItems}
                formatDuration={formatDuration}
                seasonNumber={data.seasonNumber}
                mediaId={(pageContext && pageContext.mediaId) || mediaId}
                mediaData={mediaData}
                setVideoData={setVideoData}
                isSeriesPage={isSeriesPage}
                setOpen={setOpen}
                location={location}>
              </SeriesVideoScroller>
            </section>
            </NoSsr>
          ) : ''
        }
        {/* end of episode with floating play button screen */}

        {/* start of series landing page */}
        {
          (isSeriesPage && data.seasonNumber && data.episodeNumber) ?
          (
            <NoSsr>
              <div className={classes.section}>
                {(!open && (
                  <TopNavigation
                    backgroundColor={(!scrollTrigger && "transparent") || "black"}
                  />
                )) ||
                  null}
                <Box className={classes.box}>
                  <Typography variant="h1">
                    {(isSeriesPage &&
                      mediaData.title &&
                      mediaData.title.toUpperCase()) ||
                      (data.title && data.title.toUpperCase())}
                  </Typography>
                  <br />
                  <br />
                  <Typography
                    variant="body2"
                    className={classes.desktopDescription}
                  >
                    {(!isSeriesPage && data.description) || mediaData.description}
                  </Typography>
                  <br />
                  <Grid container display="flex" justifyContent="flex-start" alignItems="center">
                    <Grid item>
                    <Button
                      variant="outlined"
                      style={{
                        color: "white",
                        borderRadius: "15px",
                        borderWidth: "1px",
                        borderColor: "white",
                      }}
                      onClick={handleVideo}
                    >
                      {(`Watch S${data.seasonNumber} E${data.episodeNumber}`)}
                    </Button>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <Box className={classes.mobileBox}>
                <Box className={classes.titleCategory}>
                  <Typography variant="h1">
                    {(isSeriesPage &&
                      mediaData.title &&
                      mediaData.title.toUpperCase()) ||
                      (data.title && data.title.toUpperCase())}
                  </Typography>
                </Box>
                <br />
                <br />
                <Typography variant="body2" className={classes.desktopDescription}>
                  {(!isSeriesPage && data.description) || mediaData.description}
                </Typography>
                <br />
                <Grid container display="flex" justifyContent="flex-start" alignItems="center">
                  <Grid item>
                    <Button
                      variant="outlined"
                      style={{
                        color: "white",
                        borderRadius: "15px",
                        borderWidth: "1px",
                        borderColor: "white",
                      }}
                      onClick={handleVideo}
                    >
                      {`Watch S${data.seasonNumber} E${data.episodeNumber}`}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <section className={classes.videoScroller}>
                <SeriesVideoScroller
                  content={seriesItems}
                  formatDuration={formatDuration}
                  seasonNumber={data.seasonNumber}
                  mediaId={(pageContext && pageContext.mediaId) || mediaId}
                  mediaData={mediaData}
                  setVideoData={setVideoData}
                  isSeriesPage={isSeriesPage}
                  setOpen={setOpen}
                  location={location}
                ></SeriesVideoScroller>
              </section>
            </NoSsr>
          ) : ''
        }
        {/* ends of series landing page */}
      </React.Fragment>
    );
  };

  return (
    <Layout location={location} seo={seo}>
      <Helmet>
          <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org",
                  "@type": "VideoObject",
                  "name": "${seo.title}",
                  "description": "${seo.description}",
                  "thumbnailUrl": [
                    "${seo.image}"
                  ],
                  "uploadDate":  "${seo.uploadDate}",
                  "duration":  "${seo.duration}",
                  "contentUrl":  "${seo.contentUrl}"
                }
              `}
          </script>
        </Helmet>
      <FullScreenTextLoader open={isLoading && !isImageLoaded} />
      {renderVideo()}
    </Layout>
  );
}

const useStyles = makeStyles((theme) => ({
  mobileDurationGrid: {
    justifyContent: "space-between",
  },
  mobileDuration: {
    margin: "7px 0",
  },
  desktopDescription: {
    fontSize: "1rem",
    lineHeight: "1.4",
  },
  desktopFeedId: {
    cursor: "pointer",
    marginLeft: "30px", 
  },desktopIndexId:{zIndex:1},
  link: {
    cursor: "pointer",
  },
  mobileDesciption: {
    width: "100%",
  },
  videoScroller: {
    margin: "16px",
  },
  titleCategory: {
    // display: 'flex',
    // justifyContent: 'space-between'
  },
  modal: {
    backgroundColor: "black",
  },
  paper: {
    position: "absolute",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: "80vw",
    [theme.breakpoints.between(0, 600)]: {
      top: `50%`,
      left: `50%`,
      width: "100vw",
    },
  },
  text: {
    padding: 8,
  },
  mobileWatchBox: {
    display: "flex",
    justifyContent: "center",
  },
  section: {
    backgroundImage: ({ image }) =>
      `linear-gradient(90deg,#000 2%,transparent), url(${image})`,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: ({ image }) =>
        `linear-gradient(180deg,#000 -2%,transparent), url(${image})`,
    },
    [theme.breakpoints.down("md")]: {
      height: "50vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "85vh",
    },
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
  },
  box: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginLeft: "10vw",
    width: "35vw",
    marginTop: "15vh",
  },
  mobileBox: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    padding: "16px",
  },
  durationDiv:{
    marginRight: "25px",
  },
  seasonDiv:{
    marginRight: "25px",
  }
}));
