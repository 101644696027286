import React from 'react';
import {
  Box, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function CenterText({ text }) {
  const classes = useStyles();

  return (
    <section className={classes.section} >
      <Box className={classes.box}>
        <Typography
          className={classes.text}
          variant="h1">
          {text || 'This page content is not available in your region.'}
        </Typography>

      </Box>
    </section>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.between(0, 600)]: {
      height: '55vh'
    },
    height: '80vh'
  },
  text: {
    padding: 8
  }
}));
