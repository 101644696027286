import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Carousel } from "../index";
import { Box, Button, Typography } from "@material-ui/core";
import { navigate } from "gatsby";
import { parseUrl, parseTitle, parseImage } from "helpers/string";
import { FloatingPlayIcon } from "components";

const LandingVideoScroller = ({
  content,
  fromSearchPage,
  setIsLandingPage,
  isSeriesDetailPage,
  mediaId,
  setIsLoading,
}) => {
  const classes = useStyles();
  const [dragging, setDragging] = useState(false);
  const [errors, setErrors] = useState({});

  const callDragging = () => {
    setDragging(true);
  };

  const changePage = ({ link, item, fromSearchPage }) => {
    setIsLoading(true);
    setIsLandingPage(false);
    navigate(link, {
      state: {
        item,
        relatedItems: content,
        fromSearchPage,
        fromLandingPage: true,
      },
    });
  };

  const slickConfig = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: false,
    swipeToSlide: true,
    variableWidth: true,
    draggable: true,
    beforeChange: () => callDragging(),
    afterChange: () => setDragging(false),
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 780,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const renderItems = () => {
    if (fromSearchPage) {
      if (content && content.videos && content.videos.length) {
        return content.videos.map((item) => {
          let image = parseImage(item.custom && item.custom.smallFeaturedImage);
          if (!image && !item.custom.seriesPlayListId) return null;
          const title = parseTitle(item.title);
          image = parseUrl(image);
          let link = `/details/${title}/${item.key}`;
          const seriesPlayListId =
            item.seriesPlayListId || item?.custom?.seriesPlayListId;
          if (seriesPlayListId) {
            link = `/series/${title}/${item.key}/`;
          }
          return (
            <div
              key={title}
              onClick={() => {
                if (dragging) return;
                changePage({ link, item, fromSearchPage });
              }}
            >
              <div className={classes.imageBox}>
                <img className={classes.image} alt={item.title} src={image} />
              </div>
              <Typography variant="body1" className={classes.watchText}>
                Watch Now
              </Typography>
            </div>
          );
        });
      }
    }
    if (!(content && content.playlist && content.playlist.length)) {
      return null;
    }
    return content.playlist.map((item, index) => {
      let image = parseImage(item.smallFeaturedImage);
      if (!image) {
        image = item.images.find((i) => i.width === 480);
        image = image && image.src;
      }
      image = parseUrl(image);
      const title = parseTitle(item.title);
      let link = `/details/${title}/${item.mediaid}/`;
      if (item.seriesPlayListId) {
        link = `/series/${title}/${item.mediaid}/`;
      } else if (isSeriesDetailPage) {
        const seriesTitle = parseTitle(content.title);
        const title = parseTitle(item.title);
        link = `/series/${seriesTitle}/season-${item.seasonNumber}/ep-${item.episodeNumber}/${title}/${mediaId}/`;
      }
      return (
        <div
          key={title}
          onClick={() => {
            if (dragging) return;
            changePage({ link, item, fromSearchPage });
          }}
        >
          <div className={classes.imageBox}>
            <FloatingPlayIcon float />
            <img
              className={classes.image}
              alt={item.title}
              src={image}
              onError={(e) => {
                if (!errors[item.title]) {
                  const ev = { ...errors };
                  ev[item.title] = true;
                  setErrors(ev);
                  e.target.src = item.image;
                }
                e.target.src = item.image;
              }}
            />
          </div>
          <Typography variant="body1" className={classes.watchText}>
            Watch Now
          </Typography>
        </div>
      );
    });
  };
  let title = content && content.title && content.title.toLowerCase();
  if (fromSearchPage) {
    title = "search";
  }
  if (
    (content &&
      content.playlist &&
      content.playlist.length &&
      content.playlist.length < 6) ||
    (content &&
      content.videos &&
      content.videos.length &&
      content.videos.length < 6)
  ) {
    slickConfig.infinite = false;
    slickConfig.arrows = false;
  }
  // const categoryLinkTitle = parseTitle(title);
  return (
    <Box>
      {/* <Typography onClick={() => {
                navigate(`/category/${categoryLinkTitle}/${mediaId}/`)
            }} className={classes.title}
                variant="h6">{title}</Typography> */}
      <Box className={classes.box}>
        <Carousel config={slickConfig}>{renderItems()}</Carousel>
      </Box>
    </Box>
  );
};

export default LandingVideoScroller;

const useStyles = makeStyles((theme) => ({
  watchText: {
    padding: "8px 0",
    cursor: "pointer",
    width: "110px",
  },
  watchButton: {
    marginTop: "12px",
    color: "white",
    borderRadius: "18px",
    borderWidth: "1px",
    borderColor: "white",
  },
  title: {
    cursor: "pointer",
    padding: "8px",
    marginLeft: "8px",
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0",
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".85rem",
    },
  },
  box: {
    margin: "0 8px !important",
    [theme.breakpoints.up("md")]: {
      margin: "0 16px !important",
    },
  },
  imageBox: {
    outlineWidth: "inherit",
    position: "relative",
    cursor: "pointer",
    marginRight: "16px !important",
    // transition: "transform 0.3s",
    // "&:hover": {
    //   transform: "scale(1.03)",
    //   transitionTimingFunction: "ease-in-out",
    //   transition: "0.3s",
    // },
  },
  root: {
    flexGrow: 1,
    // width: `240px !important`,
    height: "125px !important",
    margin: 8,
  },
  image: {
    // width: `240px !important`,
    marginTop: "5px",
    borderRadius: "8px",
    height: "220px !important",
    [theme.breakpoints.between(0, 768)]: {
      height: "115px !important",
    },
    [theme.breakpoints.between(769, 1223)]: {
      height: "155px !important",
    },
  },
}));
