import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography
} from '@material-ui/core';

const CustomForm = ({ status, message, onValidated, classes, handleClose }) => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    films: ''
  });
  const [error, setError] = useState('')
  const submit = () => {
    const { email, films, name } = form;
    if (email &&
      email.indexOf("@") > -1) {
      onValidated({
        MERGE0: email,
        MERGE1: name,
        MERGE2: films,
        id: 'b002387911',
      });
    } else {
      setError('Please enter a valid email.')
    }
  }
  useEffect(() => {
    let timer;
    if (status === 'success') {
      timer = setTimeout(() => {
        handleClose()
      }, 5000)
    }
    return () => {
      clearTimeout(timer);
    }
  }, [status])

  // {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}

  return (
    <div>
      {status === "success" && (
        <Box display="flex"
          justifyContent="center"
          textAlign="center" m={2}
          className={classes.thankYouBox}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ color: "white" }}
          >
            Thank you. You have been successfully subscribed.
          </Typography>
        </Box>
      ) || (
          <React.Fragment>
            <Box display="flex"
              justifyContent="center"
              textAlign="center" m={2}
              className={classes.updateBox}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: "white" }}
              >
                Stay up to date with new releases and more.
              </Typography>
            </Box>
            {
              (status === "error" || error) && (
                <Box display="flex"
                  justifyContent="center"
                  textAlign="center" m={2}>
                  <Typography
                    id="errorClass"
                    className={classes.errorClass}
                    variant="body2"
                    style={{ color: "red !important" }}
                    dangerouslySetInnerHTML={{
                      __html: message || error
                    }}
                  />
                </Box>
              )
            }
            <TextField
              id="name"
              autoComplete="off"
              label="enter your name"
              variant="outlined"
              className={classes.nameTextField}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              InputProps={{
                classes: {
                  root: classes.textRoot,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                  focused: classes.input,
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                },
              }}
            />
            <TextField
              id="email"
              label="enter your email"
              autoComplete="off"
              variant="outlined"
              className={classes.emailTextField}
              onChange={(e) => {
                setForm({ ...form, email: e.target.value })
                setError('')
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                  focused: classes.input,
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                },
              }}
            />
            <br />
            <TextField
              style={{ marginTop: "2%", color: "white" }}
              fullWidth
              autoComplete="off"
              id="films"
              label="films you love?"
              onChange={(e) => setForm({ ...form, films: e.target.value })}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                  focused: classes.input,
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                },
              }}

            />
            <br />
            <Button onClick={submit}
              size="large" variant="contained"
              style={{ marginTop: "2%", borderRadius: '0' }}
              fullWidth>
              submit
            </Button>
          </React.Fragment>
        )}
    </div>
  );
};

export default CustomForm;