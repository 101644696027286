import React, { useEffect } from "react";
import {
  Box,
  NoSsr,
  Typography,
  Modal,
  useScrollTrigger,
  Fade,
} from "@material-ui/core";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "gatsby";
import { TopNavigation, FloatingPlayIcon } from "components";
import { VideoDetailNavigation } from "components/TopNavigation";
import {
  VideoPlayer,
  CenterText,
  LandingVideoScroller,
  LandingVerticalVideoScroller,
} from "components/index";
import { parseUrl, parseTitle } from "helpers/string";

const LandingVideoDetail = ({
  setIsLandingPage,
  setIsLoading,
  isVideoDetailPage,
  isSeriesDetailPage,
  mediaId,
  data,
  relatedItems,
  image,
  isLoading,
}) => {
  const [open, setOpen] = React.useState(false);
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  useEffect(() => {
    let timer;
    if (!isImageLoaded && image) {
      const loadImg = new Image();
      loadImg.src = image;
      loadImg.onload = () => setIsImageLoaded(true);

      timer = setTimeout(() => {
        if (!isImageLoaded) {
          setIsImageLoaded(true);
        }
      }, 11000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [image]);

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  const classes = useStyles({ image: parseUrl(image) });

  const playButtonClick = () => {
    if (
      !(relatedItems && relatedItems.playlist && relatedItems.playlist.length)
    )
      return;
    const item = relatedItems.playlist[0];

    const title = parseTitle(item.title);
    let link = `/details/${title}/${item.mediaid}/`;
    if (item.seriesPlayListId) {
      link = `/series/${title}/${item.mediaid}/`;
    } else if (isSeriesDetailPage) {
      const seriesTitle = parseTitle(relatedItems.title);
      const title = parseTitle(item.title);
      link = `/series/${seriesTitle}/season-1/ep-1/${title}/${mediaId}/`;
      setIsLandingPage(false);
      setIsLoading(true);
      navigate(link, {
        state: {
          // mediaRetVal: mediaData,
          retVal: relatedItems,
          fromLandingPage: true,
        },
      });
    }
  };
  const renderVideo = () => {
    if (isLoading) {
      return <section style={{ height: "60vh" }}></section>;
    }
    if (!data) {
      // navigate('/');
      return (
        <React.Fragment>
          <TopNavigation key="topNavigation" />
          <CenterText key="centerText" />
        </React.Fragment>
      );
    }

    const videoUrl = data.mrss_video_url;

    const title =
      relatedItems && relatedItems.title && relatedItems.title.toLowerCase();
    // const categoryLinkTitle = parseTitle(title);
    return (
      <React.Fragment>
        <NoSsr defer>
          <Modal
            className={classes.modal}
            disableBackdropClick={true}
            open={open}
            onClose={() => {
              trackCustomEvent({
                category: "video",
                action: data.title,
                label: "Exit",
              });
              setOpen(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.paper}>
              <VideoPlayer
                title={data.title}
                mediaId={mediaId}
                sources={data.sources}
                open={open}
                url={videoUrl}
              />
            </div>
          </Modal>
          {(open && (
            <VideoDetailNavigation
              onClose={() => setOpen(false)}
              title={data.title}
            />
          )) ||
            null}
        </NoSsr>
        <NoSsr>
          <div className={classes.section}>
            {window && window.innerWidth < 960 && (
              <FloatingPlayIcon float onClick={playButtonClick} />
            )}
            {(!open && (
              <Fade in={scrollTrigger} timeout={4000}>
                <TopNavigation
                  backgroundColor={(!scrollTrigger && "transparent") || "black"}
                />
              </Fade>
            )) ||
              null}
            <Box className={classes.box}>
              <Typography
                variant="h1"
                style={{
                  fontSize: "2.728rem",
                  color: data.fontColor,
                  fontFamily: "HelveticaBold",
                }}
              >
                {data.title && data.title.toUpperCase()}
              </Typography>
            </Box>
          </div>
          <section className={classes.detailDescription}>
            <Box textAlign="center" className={classes.headingBox}>
              <Typography
                variant="h1"
                className={classes.mobileHeading}
                style={{ color: data.fontColor, fontFamily: "HelveticaBold" }}
              >
                {data.title && data.title.toUpperCase()}
              </Typography>
              <Typography variant="h2" 
                style={{
                  fontFamily: "HelveticaBold",
                }}  className={classes.mobileSubHeading}>
                {data.subHead && data.subHead.toUpperCase()}
              </Typography>

              <Typography
                variant="body2"
                className={classes.desktopDescription}
              >
                {data.description}
              </Typography>
            </Box>
          </section>
          <section className={classes.videoScroller}>
            <LandingVideoScroller
              setIsLoading={setIsLoading}
              data={data}
              mediaId={mediaId}
              setIsLandingPage={setIsLandingPage}
              isVideoDetailPage={isVideoDetailPage}
              isSeriesDetailPage={isSeriesDetailPage}
              content={relatedItems}
            ></LandingVideoScroller>
          </section>
          <section className={classes.verticalVideoScroller}>
            <LandingVerticalVideoScroller
              setIsLoading={setIsLoading}
              data={data}
              mediaId={mediaId}
              setIsLandingPage={setIsLandingPage}
              isVideoDetailPage={isVideoDetailPage}
              isSeriesDetailPage={isSeriesDetailPage}
              content={relatedItems}
            ></LandingVerticalVideoScroller>
          </section>
        </NoSsr>
      </React.Fragment>
    );
  };

  return <React.Fragment>{renderVideo()}</React.Fragment>;
};

const useStyles = makeStyles((theme) => ({
  headingBox: {
    margin: "64px 64px 72px",
    [theme.breakpoints.down(720)]: {
      margin: "32px 8px 8px",
    },
  },
  mobileSubHeading: {
    [theme.breakpoints.down(960)]: {
      fontSize: "0.8rem",
    },
  },
  mobileHeading: {
    marginBottom: "24px",
    [theme.breakpoints.up(960)]: {
      display: "none",
    },
  },
  verticalVideoScroller: {
    padding: "0 30px",
    margin: "0 auto",
    [theme.breakpoints.up(960)]: {
      maxWidth: "1400px",
    },
    [theme.breakpoints.down(960)]: {
      padding: "0 16px",
    },
  },
  detailDescription: {
    padding: "",
    padding: "0 30px",
    margin: "0 auto",
    [theme.breakpoints.up(960)]: {
      maxWidth: "1400px",
    },
    [theme.breakpoints.down(960)]: {
      padding: "0 16px",
    },
  },
  desktopDescription: {
    fontSize: "1rem",
    lineHeight: "1.4",
    margin: "1.5rem 5rem 0",
    [theme.breakpoints.down(960)]: {
      margin: "1.5rem 1.5rem 0",
    },
  },
  link: {
    cursor: "pointer",
  },
  mobileDesciption: {
    width: "100%",
  },
  videoScroller: {
    margin: "16px",
    padding: "0 30px",
    maxWidth: "1400px",
    margin: "0 auto",
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
  },
  titleCategory: {
    // display: 'flex',
    // justifyContent: 'space-between'
  },
  modal: {
    backgroundColor: "black",
  },
  paper: {
    position: "absolute",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: "80vw",
    [theme.breakpoints.between(0, 600)]: {
      top: `50%`,
      left: `50%`,
      width: "100vw",
    },
  },
  text: {
    padding: 8,
  },
  mobileWatchBox: {
    display: "flex",
    justifyContent: "center",
  },
  section: {
    position: "relative",
    backgroundImage: ({ image }) => `url(${image})`,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: ({ image }) => `url(${image})`,
    },
    [theme.breakpoints.down("md")]: {
      height: "50vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "85vh",
    },
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
  },
  box: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginLeft: "10vw",
    width: "40vw",
    marginTop: "25vh",
  },
  mobileBox: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    padding: "16px",
  },
}));

export default LandingVideoDetail;
