import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Container, Grid, Typography, Divider, Link as MLink } from '@material-ui/core';
import { Link } from "gatsby";

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';

import env from '../../config';
import AppIcons from '../AppIcons';
import FreeTvIcons from '../FreeTvIcons';

const socialLinks = [
  {
    label: 'Facebook',
    href: env.fbUrl,
    icon: FacebookIcon,
  },
  {
    label: 'Instagram',
    href: env.igUrl,
    icon: InstagramIcon,
  },
  {
    label: 'Twitter',
    href: env.twitterUrl,
    icon: TwitterIcon,
  },
  {
    label: 'YouTube',
    href: env.ytUrl,
    icon: YouTubeIcon,
  },
];

const staticLinks = [
  {
    label: 'TERMS OF USE',
    href: '/terms-of-use/',
  },
  {
    label: 'INFO',
    href: '/info/',
  },
  {
    label: 'PRIVACY POLICY',
    href: '/privacy-policy/',
  }
];

const StickyFooter = ({ location, isFooterIcons }) => {
  const classes = useStyles();

  const LinkStatic = ({ label, href }) => {
    return (
      <StyledLink className={(location && location.pathname === href) && classes.background}
        key={label} to={href}>{label}</StyledLink>
    );
  };

  const LinkSocial = ({ href, label, icon }) => {
    return (
      <SocialLink
        href={href}
        aria-label={label}
        rel="noopener noreferrer"
        target="_blank"
      >
        {icon.type.render()}
      </SocialLink>
    );
  };


  return (
    <Footer >
      {isFooterIcons ?
        <Grid container justify="center">
          <Grid item xs={12} md={8} lg={6}>
            <Box textAlign="center">
              <Typography className={classes.streamText} variant="h6">now streaming on</Typography>
            </Box>
          </Grid>
          <AppIcons />
          <FreeTvIcons compact={true} />
          <Divider className={classes.divider} />
        </Grid>
        : null}
      <StyledContainerLinks maxWidth="lg">
        <Box display="flex" flexDirection="row">
          {socialLinks.map(({ label, href, icon }) => (
            <LinkSocial key={label} label={label} href={href} icon={icon} />
          ))}
        </Box>
        <Box display="flex" flexDirection="row" className="links">
          <Box>
            {staticLinks.map(({ label, href }) => (
              <LinkStatic key={label} label={label} href={href} />
            ))}
          </Box>
        </Box>
      </StyledContainerLinks>
    </Footer>
  );
};

export default StickyFooter;

const useStyles = makeStyles((themes) => ({
  background: {
    padding: '8px !important',
    backgroundColor: '#585858',
    borderRadius: '3px'
  },
  iconGrid: {
    [themes.breakpoints.down('md')]: {
      display: 'none',
    },
    padding: '15px 25vw',
    justifyContent: 'space-between'
  },
  mobileIconGrid: {
    [themes.breakpoints.up('lg')]: {
      display: 'none',
    },
    [themes.breakpoints.up(700)]: {
      padding: '10px 25vw',
    },
    padding: '10px 8vw',
    justifyContent: 'space-around'
  },
  mobileGrid: {
    [themes.breakpoints.up('lg')]: {
      display: 'none',
    },
    [themes.breakpoints.up(700)]: {
      padding: '10px 15vw',
    },
    padding: '0 5vw',
    justifyContent: 'space-around'
  },
  mobileIcon: {
    padding: '0 10px',
    height: '100%',
    width: '65%'
  },
  iconRoot: {
    height: 65,
    width: 220
  },
  mobileImageIcon: {
    height: '100%',
    width: '55%'
  },
  imageIcon: {
    height: '100%',
    width: '50%'
  },
  streamText: {
    color: themes.body.color,
    padding: 16
  },
  divider: {
    backgroundColor: themes.body.color,
    width: '80%',
    margin: 16
  }
}));

const Footer = styled.footer`
  flex: initial;
`;

const SocialLink = styled(MLink)`
  & > svg {
    cursor: pointer;
    color: white;
    margin: 0 5px;
  }
`;

const StyledContainerLinks = styled(Container)`
  font-size: 10px;
  padding: 40px 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
    align-items: center;

  & .links {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    line-height: 20px;
    padding-top: 28px;

    & a {
      padding: 0 8px;
      white-space: nowrap;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    & .links {
      flex-direction: row;
      font-size: 10px;
      padding-top: 0;
    }
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: center;
    & .links {
      font-size: 14px;
      padding: 0;
    }
  }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #ffffff;
    margin: 8px;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;
