import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Typography,
    Grid
} from '@material-ui/core';
import { Link } from "gatsby"
import { parseUrl, parseTitle, parseImage } from 'helpers/string'

const VideoList = ({ content, showTitle, showCenterTitle, fromSearchPage }) => {
    const classes = useStyles();
    const [errors, setErrors] = useState({})

    const renderItems = () => {
        if (!(content && content.playlist && content.playlist.length) && !(
            content && content.videos && content.videos.length)) {
            return <div style={{ height: '100vh' }}></div>;
        }
        else if (fromSearchPage && content.videos && content.videos.length) {
            return content.videos.filter(item => {
                const tags = item.tags || '';
                if (tags.includes('searchHide')) {
                    return false;
                }
                return true;
            }).map(item => {
                let image = item.custom && item.custom.smallFeaturedImage || '';
                if (!image && !item.custom.seriesPlayListId) return null;
                const title = parseTitle(item.title);
                image = parseUrl(image);
                image = parseImage(image);
                let url = `/details/${title}/${item.key}/`;
                const seriesPlayListId = item.seriesPlayListId || item?.custom?.seriesPlayListId;
                if (seriesPlayListId) {
                    url = `/series/${title}/${item.key}/`
                }
                return (
                    <Grid key={item.title} className={classes.imageBox} item>
                        <Link to={url}
                            state={{
                                item,
                                relatedItems: content,
                                fromSearchPage
                            }}>
                            <img className={classes.image}
                                alt={item.title}
                                src={image}
                                onError={(e) => {
                                    if (!errors[item.title]) {
                                        const ev = { ...errors };
                                        ev[item.title] = true;
                                        setErrors(ev)
                                        e.target.src = item.custom.image
                                    }
                                }} />
                        </Link>
                    </Grid>
                )
            })
        }
        else {
            return content.playlist.map(item => {
                let image = parseImage(item.smallFeaturedImage);
                if (!image) {
                    image = item.images && item.images.length &&
                        item.images.find(i => i.width === 480);
                    image = image && image.src;
                }
                if (!image) return null;
                const title = parseTitle(item.title);
                image = parseUrl(image);
                let link = `/details/${title}/${item.mediaid}/`;
                if (item.seriesPlayListId) {
                    link = `/series/${title}/${item.mediaid}/`
                }
                return (
                    <Grid key={item.title} className={classes.imageBox} item >
                        <Link to={link}
                            state={{
                                item,
                                relatedItems: content,
                                fromSearchPage,
                                fromFilmsPage: true
                            }}>
                            <img className={classes.image}
                                alt={item.title}
                                src={image}
                                onError={(e) => {
                                    if (!errors[item.title]) {
                                        const ev = { ...errors };
                                        ev[item.title] = true;
                                        setErrors(ev)
                                        e.target.src = item.image
                                    }
                                }}
                            />
                        </Link>
                    </Grid>
                )
            })

        }
    }


  

    let heading = (content && content.title && content.headingAddition) ? `${(content.title)} ${content.headingAddition}` : (content && content.title); 
    
    return (
      <Box className={classes.root}>
        {(showTitle && (
          <Typography className={classes.title} variant="body2">
            { (content && content.title) } 
          </Typography>
        )) ||
          null}
        {(showCenterTitle && (
          <Typography className={classes.centerTitle} variant="h1">
            { (heading) }
          </Typography>
        )) ||
          null}
        {(content && content.description && (
          <Box className={classes.descriptionBox}>
            <Typography className={classes.description} variant="body2">
              {content.description}
            </Typography>
          </Box>
        )) ||
          null}
        <Grid container className={classes.gridContainer}>
          {renderItems()}
        </Grid>
        {(content && content.lowerDescription && (
          <Box className={classes.lowerDescriptionBox}>
            <Typography className={classes.description} variant="body2">
              {content.lowerDescription}
            </Typography>
          </Box>
        )) ||
          null}
      </Box>
    );
};

export default VideoList;

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        display: 'flex',
        justifyContent: 'left',
    },
    imageBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        width: '20%',
        padding: '12px 8px',
        [theme.breakpoints.between(0, 770)]: {
            width: '50%'
        },
        [theme.breakpoints.between(770, 1024)]: {
            width: '33.3%'
        },
        [theme.breakpoints.between(1024, 1200)]: {
            width: '25%'
        },
        cursor: 'pointer'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        flexGrow: 1,
        marginTop: '20px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        },
    title: {
        padding: '8px',
        marginLeft: '24px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '16px !important'
        },
    },
    descriptionBox: {
        margin: '0 15% 24px 15%', textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            margin: '0 5% 24px 5%',
        },
    },
    centerTitle: {
        marginBottom: 24,
        fontSize: '1.6rem',
        fontWeight: 500
    },
    image: {
        borderRadius: '8px',
        width: '100%'
    },
    lowerDescriptionBox: {
        margin: '2% 15% 24px 15%', textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            margin: '2% 5% 24px 5%',
        },
    },
}));
