import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Carousel } from '../index'
import {
    Box,
} from '@material-ui/core';
import { navigate } from "gatsby"
import './featureSlider.css';
import { parseTitle, parseImage } from 'helpers/string'

const VideoScroller = ({ featuredContent }) => {
    const classes = useStyles();
    const [dragging, setDragging] = useState(false);
    const slickConfig = {
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        cssEase: 'ease',
        slidesToShow: 1,
        variableWidth: true,
        centerMode: true,
        focusOnSelect: true,
        pauseOnHover: true,
        swipeToSlide: true,
        beforeChange: () => setDragging(true),
        afterChange: () => setDragging(false),
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    variableWidth: false,
                    centerMode: false,
                    slidesToShow: 1,
                    dots: true,
                    arrows: false,
                },
            }
        ],
    };

    const renderItems = () => {
        let featuredSlides = [];
        if (!(featuredContent && featuredContent.playlist && featuredContent.playlist.length)) {
            return null;
        }
        if (featuredContent.playlist.length) {
            const playListSlides = featuredContent.playlist.map(item => {
                const image = parseImage(item.smallFeaturedImage);
                const title = parseTitle(item.title);
                let link = `/details/${title}/${item.mediaid}/`;
                if (item.seriesPlayListId) {
                    link = `/series/${title}/${item.mediaid}/`
                }
                if (item.type) {
                    link = `/category/${item.type}/${item.feedid}/`;
                }
                return {
                    link,
                    image,
                    item,
                }
            });
            featuredSlides = featuredSlides.concat(playListSlides);
        }
        if (featuredContent.webOnly === "true") {
            const featuredImageIndex = Number(featuredContent.position) - 1;
            const featuredPlayList = {
                link: `/category/${featuredContent.title.toLowerCase()}/${featuredContent.playlistID}`,
                title: featuredContent.title,
                image: featuredContent.featuredImage,
            }
            featuredSlides.splice(featuredImageIndex, 0, featuredPlayList);
        }
        return (
            featuredSlides.map((item, i) => (
                <div key={i}
                    role="button"
                    onClick={(e) => {
                        e.preventDefault();
                        if (dragging) return;
                        navigate(item.link, { state: { item: item.item, relatedItems: featuredContent } })
                    }}
                    className={classes.imageBox}
                >
                    <img className={classes.image}
                        alt={item.title}
                        src={item.image}
                    />
                </div>
            ))
        );

    }
    return (
        <Box
            id="featured"
            className={classes.box}
        >
            <Carousel
                config={slickConfig} >
                {renderItems()}
            </Carousel>
        </Box>
    );
};

export default VideoScroller;

const useStyles = makeStyles((theme) => ({
    box: {
        margin: '0 12px !important',
        [theme.breakpoints.down(767)]: {
            margin: '0 !important'
        },
    },
    imageBox: {
        margin: '0 12px !important',
        width: '40vw !important',
        [theme.breakpoints.down(767)]: {
            width: '100vw !important',
            margin: '0 !important'
        },
    },
    image: {
        cursor: 'pointer',
        borderRadius: '8px',
        height: '100%',
        width: '100%',
        // height: '660px !important',
        transition: 'transform 0.3s',
        '&:hover': {
            transform: 'scale(1.03)',
            transitionTimingFunction: 'ease-in-out',
            transition: '0.3s',
        },
        [theme.breakpoints.down(767)]: {
            borderRadius: '0'
        },
    },
}));
